<template>
   <svg id="Grupo_57" data-name="Grupo 57" xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57">
        <g id="Grupo_56" data-name="Grupo 56">
            <path id="Trazado_290" data-name="Trazado 290" d="M586,238.081c7.61,0,8.511.029,11.517.166a15.763,15.763,0,0,1,5.292.982,9.436,9.436,0,0,1,5.409,5.409,15.775,15.775,0,0,1,.982,5.292c.137,3.006.166,3.907.166,11.517s-.029,8.511-.166,11.516a15.776,15.776,0,0,1-.982,5.292,9.436,9.436,0,0,1-5.409,5.409,15.778,15.778,0,0,1-5.292.982c-3,.137-3.906.166-11.517.166s-8.512-.029-11.517-.166a15.778,15.778,0,0,1-5.292-.982,9.436,9.436,0,0,1-5.409-5.409,15.761,15.761,0,0,1-.982-5.292c-.137-3.006-.166-3.907-.166-11.517s.029-8.511.166-11.517a15.761,15.761,0,0,1,.982-5.292,9.436,9.436,0,0,1,5.409-5.409,15.763,15.763,0,0,1,5.292-.982c3.006-.137,3.907-.166,11.517-.166m0-5.135c-7.74,0-8.711.033-11.751.172a20.922,20.922,0,0,0-6.918,1.324A14.572,14.572,0,0,0,559,242.777a20.922,20.922,0,0,0-1.324,6.918c-.139,3.04-.172,4.011-.172,11.751s.033,8.71.172,11.751A20.922,20.922,0,0,0,559,280.115a14.572,14.572,0,0,0,8.335,8.335,20.922,20.922,0,0,0,6.918,1.324c3.04.139,4.011.172,11.751.172s8.711-.033,11.751-.172a20.922,20.922,0,0,0,6.918-1.324A14.573,14.573,0,0,0,613,280.115a20.922,20.922,0,0,0,1.324-6.918c.139-3.04.172-4.011.172-11.751s-.033-8.711-.172-11.751A20.922,20.922,0,0,0,613,242.777a14.572,14.572,0,0,0-8.335-8.335,20.922,20.922,0,0,0-6.918-1.324c-3.04-.139-4.011-.172-11.751-.172Z" transform="translate(-557.5 -232.946)" :fill="color"/>
            <path id="Trazado_291" data-name="Trazado 291" d="M591.408,253.622a13.232,13.232,0,1,0,13.232,13.232A13.232,13.232,0,0,0,591.408,253.622Zm0,21.822a8.59,8.59,0,1,1,8.59-8.59A8.59,8.59,0,0,1,591.408,275.444Z" transform="translate(-562.908 -238.354)" :fill="color"/>
            <circle id="Elipse_17" data-name="Elipse 17" cx="3.766" cy="3.766" r="3.766" transform="translate(39.738 9.73)" :fill="color"/>
        </g>
    </svg>

</template>

<script>
export default {
    name: 'IgLogo',
    props: {
        color: String
    }
}; 
</script>