<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="46.325" viewBox="0 0 57 46.325">
        <g id="Grupo_59" data-name="Grupo 59" transform="translate(-1007.216 -243.321)">
            <g id="Grupo_58" data-name="Grupo 58" transform="translate(1007.216 243.321)">
            <path id="Trazado_292" data-name="Trazado 292" d="M1025.142,289.645c21.51,0,33.273-17.821,33.273-33.273q0-.759-.033-1.512a23.8,23.8,0,0,0,5.834-6.055,23.317,23.317,0,0,1-6.716,1.841,11.739,11.739,0,0,0,5.143-6.469,23.442,23.442,0,0,1-7.426,2.839,11.7,11.7,0,0,0-19.929,10.665,33.2,33.2,0,0,1-24.1-12.218,11.7,11.7,0,0,0,3.62,15.612,11.615,11.615,0,0,1-5.3-1.463c0,.049,0,.1,0,.149a11.7,11.7,0,0,0,9.382,11.465,11.683,11.683,0,0,1-5.281.2,11.708,11.708,0,0,0,10.925,8.122,23.464,23.464,0,0,1-14.524,5.006,23.733,23.733,0,0,1-2.79-.162,33.1,33.1,0,0,0,17.926,5.253" transform="translate(-1007.216 -243.321)" :fill="color"/>
            </g>
        </g>
    </svg>
</template>


<script>
export default {
    name: 'TwitterLogo',
    props: {
        color: String
    }
}

</script>
