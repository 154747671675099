<template>
  <div class="main-content campaign2-main-content">
    <div class="row container-follow-campaign" style="padding: 0 30px">
      <div class="col-9 box-nft">
        <div class="pt-5 d-flex h2 justify-content-left container-title-campaign">
          <div>
            CAMPAÑA 2.0:
          </div>
          <div class="h2 h2-black ps-2">
            Cannabis Medicinal (cáñamo de CBD)
          </div>
        </div>
        <div class="pt-5 d-flex h3 h3-green justify-content-left">
          Desarrollo de la producción:
        </div>
        <div class="textos mb-3">
          Durante los 12 meses de duración de la campaña agropecuaria, llevaremos adelante, junto a la empresa CANNBIO AGRO S.A.S., el cultivo de entre 6 a 15 hectáreas en campo abierto.
        </div>
        <div class="h3 h3-green mb-3">
          <div class="mb-3">
            <div class="mb-2" style="font-size: 20px">
              <font-awesome-icon icon="fa-solid fa-check" class="me-2" />Preparación y comienzo de la siembra: entre noviembre y diciembre de 2023 <br />
            </div>
            <div class="mb-2" style="font-size: 20px">
              <font-awesome-icon icon="fa-solid fa-check" class="me-2" />Producción destinada a obtener flores de cáñamo de CBD (Cannabis Medicinal) <br />
            </div>
            <div class="mb-2" style="font-size: 20px">
              <font-awesome-icon icon="fa-solid fa-check" class="me-2" />A Campo abierto (producción de exterior) <br />
            </div>
            <div style="font-size: 20px">
              <font-awesome-icon icon="fa-solid fa-check" class="me-2" />Genética: FedTonic principalmente, pudiendo incorporarse alguna genética especialmente solicitada por los clientes de Cannbio Agro S.A.S. <br />
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush mt-5 accordion-border-button" id="step-1">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading-1">
              <button class="accordion-button collapsed accordion-question" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                FASE 1 (DIC 2023)
              </button>
            </h2>
            <div id="collapse-1" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-1"
                      data-bs-parent="#step-1">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">PREPARACIÓN DE LA PRODUCCIÓN DE CANNABIS MEDICINAL:</b>
                            <div class="textos mb-3">
                              Durante el mes de Noviembre 2023 se realizó la fase inicial del cultivo, mediante la 
                              cual se da lugar a la etapa de germinado de las semillas y crecimiento de plántulas. 
                              Dichos procesos se dan en invernaderos aptos para tal estadío, los cuales cumplen con 
                              lo requerido para lograr una germinación óptima: oscuridad, hidratación y humedad.<br />
                            </div>
                            <div class="textos mb-3">
                              Una vez que las plántulas crecen y alcanzan los 2 o 3 nudos de altura (10-14 días aproximadamente),
                               estas se transforman en plantines, los cuales son adaptados a campo abierto y luego trasplantados 
                               para dar inicio a la fase de crecimiento y desarrollo vegetativo.<br />
                            </div>
                            <b class="h4 h3-green">TRASPLANTE A CAMPO ABIERTO:</b>
                            <div class="textos mb-3">
                              A partir del mes de Diciembre, los plantines empezaron a ser trasplantados a campo abierto, 
                              iniciando la fase de colonización de raíces en sustrato (desarrollo de raíces), fijación y adaptación 
                              al suelo y al entorno. El cultivo llevado a cabo es del tipo orgánico, dando lugar a la preservación de 
                              todas las propiedades de la planta y el fruto final, resaltando aromas, pigmentos y propiedades organolépticas.
                               Se observa un crecimiento parejo y vigoroso, buen follaje y suelo con buenas propiedades. <br />
                            </div>
                            <b class="h4 h3-green">✅ Las genéticas seleccionadas son: <br/>
                            <ul>
                                <li>Fedtonic </li> 
                                <li>HQ x HQ</li>
                            </ul>
                             </b><br/>
                            <div class="textos mb-3">
                              Todas con procedencia de banco suizo. La elección de semillas se basa en la experiencia y criterio previo 
                              de los productores, siendo las mismas las más solicitadas a nivel internacional. Ambas cuentan con alta carga 
                              de CBD, alcanzando picos de 24%, son sumamente resistentes a plagas y patógenos externos y tienen una alta 
                              capacidad de generar resina y terpenos.<br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual en Uruguay:
                            </div>
                            <div class="textos mb-3">
                              <b>Mes: Diciembre<br/> Temperaturas: mínimas de 15°C y máximas de hasta 31°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign2-phase1-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign2-phase1-2.jpg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>           
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading-1">
              <button class="accordion-button collapsed accordion-question" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                FASE 2 (ENE - FEB 2024)
              </button>
            </h2>
            <div id="collapse-2" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-1"
                      data-bs-parent="#step-2">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">I.TRASPLANTE EXITOSO EN CAMPO EXTERIOR:</b>
                            <div class="textos mb-3">
                              A partir del mes de Diciembre 2023, los plantines empezaron a ser trasplantados a campo abierto, 
                              iniciando la fase de colonización de raíces en sustrato (desarrollo de raíces), fijación y adaptación 
                              al suelo y al entorno. El mismo resultó exitoso para las plantas, toda vez que puede observarse 
                              actualmente un crecimiento en alto y ancho, como, asi también, han desarrollado follaje y nuevos brotes.<br />
                            </div>
                            <b class="h4 h3-green">II.DESARROLLO DEL CULTIVO:</b>
                            <div class="textos mb-3">
                              <b class="h4 h3-green">a. Fase Vegetativa Avanzada - Enero:</b> <br/>
                              En enero, las plantas se encontraban en una fase vegetativa avanzada, 
                              mostrando una morfología robusta con tallos y ramas resistentes, hojas 
                              anchas y carnosas de un verde oscuro intenso.<br />

                              La estructura de las mismas es del tipo arbusto abeto, caracterizándose 
                              por un crecimiento más pronunciado en diámetro que en altura y un tallo 
                              central ancho, típico de las variedades con predominancia Indica, ricas en CBD.<br />

                              Tal como van a poder observar en las fotos que se acompañan al informe, la 
                              uniformidad en el crecimiento y el color parejo de las hojas indican una alimentación 
                              óptima a través de fertirriego orgánico, sin excesos ni deficiencias.<br />

                              No se observaron ataques de plagas ni patógenos, gracias a una correcta aplicación foliar preventiva.<br />
                            </div>
                            <div class="textos mb-3">
                              <b class="h4 h3-green">b. Transición y Floración:</b> <br/>
                               Luego del trasplante en tierra, las plantas completaron su último estiramiento en la fase de 
                               pre-floración, dando paso al desarrollo de cogollos densos y ricos en resina en los meses subsiguientes.<br />

                              Este estadío marcó la transición del crecimiento vegetativo hacia una floración activa, caracterizada 
                              por cogollos grandes con pistilos blancos, indicando un desarrollo y engorde continuos.<br />

                              La salud óptima de las plantas se mantuvo, evidenciada por la ausencia de excesos y/o carencias nutricionales, 
                              sumado a la continua prevención de plagas y enfermedades por parte del equipo productivo de Uruguay.<br />
                            </div>
                            <div class="textos mb-3">
                              <b class="h4 h3-green">c. Desarrollo de los Cogollos:</b> <br/>
                               El desarrollo de los cogollos nos indica un estado activo de crecimiento y engorde. Este es un precursor 
                               de la fase avanzada de floración, donde se estabilizan para comenzar el ciclo de maduración de terpenos.<br />
                            </div>
                            <b class="h4 h3-green">III.SALUD Y MANEJO DEL CULTIVO:</b>
                            <div class="textos mb-3">
                              La implementación de prácticas de manejo adecuadas, incluyendo la aplicación de foliar preventiva y la 
                              colocación de tutores para soportar el peso de los frutos, ha sido fundamental para mantener la salud y 
                              la integridad de las plantas. <br />
                            </div>
                             <div class="textos mb-3">
                              La disposición espacial que puede observarse en el trasplante realizado en campo exterior 
                              (distancia entre plantas) ha prevenido eficazmente la formación de bolsas de humedad, lo cual resulta 
                              crucial para el mantenimiento de un ambiente óptimo para el cultivo. <br />
                            </div>
                            <b class="h4 h3-green">IV.PROYECCIONES:</b>
                            <div class="textos mb-3">
                             Desde enero hasta la actualidad hemos observado un progreso notable en cada fase del ciclo de crecimiento 
                             de nuestras plantas de cannabis (CBD) en todas sus genéticas. <br />
                            </div>
                             <div class="textos mb-3">
                              La transición exitosa a través de cada una de estas fases refleja la eficacia de las prácticas de cultivo 
                              y manejo de nuestros productores, así como nuestro compromiso con la producción sostenible y de alta calidad.<br />
                            </div>
                            <div class="textos mb-3">
                              En CryptoCampo seguimos dedicados a optimizar cada una de las etapas, con el fin de asegurar la excelencia y 
                              el liderazgo en la industria del cannabis de alta calidad.<br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign2-phase2-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign2-phase2-2.jpg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
          </div>
            <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading-1">
              <button class="accordion-button collapsed accordion-question" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                FASE 3 (MARZO - ABRIL 2024)
              </button>
            </h2>
            <div id="collapse-3" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-1"
                      data-bs-parent="#step-3">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <div class="textos mb-3">
                              El proceso de cultivo de CBD en exterior consta de varias etapas clave, cada una crucial para garantizar 
                              la calidad y el rendimiento óptimo del producto final.<br />
                              En los meses anteriores transitamos del crecimiento de las plántulas a la fase Vegetativa, la cual marca el 
                              crecimiento sustancial de las plantas.<br />
                              Es esta etapa, pasamos de plántulas a plantines adaptados a las condiciones﻿ exteriores. Durante aproximadamente 
                              45 días, las plantas desarrollaron una estructura frondosa tipo arbusto abeto, lo que sentó las bases para una 
                              cosecha abundante y de alta calidad que actualmente estamos llevando adelante.<br />
                            </div>
                            <b class="h4 h3-green">MARZO: FASE DE FLORA AVANZADA EN CAMPO EXTERIOR:</b>
                            <div class="textos mb-3">
                              Durante la fase de pre-floración, que marca la transición entre la fase vegetativa avanzada y el inicio del período 
                              de floración, hemos presenciado un crecimiento notable en nuestras plantas de cannabis medicinal.<br />
                              Al centrar sus energías en el desarrollo, producción, engorde y maduración de los frutos (cogollos/flores), observamos 
                              ejemplares que superan los dos metros de altura. Los mismos se caracterizan por sus flores densas y resinosas, con terpenos 
                              muy definidos, aromas marcados y una pigmentación distintiva.<br />
                              Se destaca además el perfil organoléptico, típico de nuestros cultivos orgánicos, que añade un valor adicional a la producción 
                              actual.<br />
                              Otro aspecto a resaltar es el punto del corte en cuanto a maduración de los tricomas, la pigmentación final obtenida en los cogollos, 
                              densidad y capa de resina.<br />
                              Esto mismo se puede ver en la imagen debajo, la cual fue tomada en los campos de Sauce (Uruguay).<br />
                            </div>
                            <div class="textos mb-3">
                              <b class="h4 h3-green">FINES DE MARZO - ABRIL: COSECHA</b> <br/>
                               A partir de fines del mes de marzo se comenzó con la cosecha entre los diversos campos, especialmente en Sauce, donde se encuentra 
                               la mayor parte de la producción de cannabis medicinal.<br />
                               Con el objetivo de optimizar el proceso, hemos implementado cosechas escalonadas y un método de cosecha parcial y seccionado. 
                               Este enfoque nos permite manejar eficientemente el volumen y el espacio en las cámaras de secado, así como abordar la variabilidad en el nivel 
                               de maduración de las plantas. Específicamente, la presencia de ejemplares conocidos como "Monster Plants" ha requerido una atención especial, 
                               dado su rápido crecimiento durante las primeras semanas de cambio de foto período.<br />
                               Las genéticas que actualmente están siendo cosechadas y sometidas al tratamiento post cosecha incluyen las variedades Ultra Pink, Fenocheese y 
                               Fedtonic.<br />
                               Estamos entusiasmados con los resultados obtenidos hasta el momento y esperamos compartir más detalles sobre el proceso de post cosecha en 
                               nuestro próximo informe.<br />
                            </div>
                            <div class="textos mb-3">
                              <b class="h4 h3-green">PROYECCIONES:</b> <br/>
                               La producción de CBD del período de marzo-abril de 2024 ha sido altamente positiva, destacando tanto el éxito en la fase de flora avanzada como 
                               en el proceso de cosecha en curso.<br />
                               Durante el mes de abril vamos a seguir cosechando de forma escalonada, respetanto los tiempos de la planta, cuidando y controlando la humedad a 
                               fin de obtener producto de alta calidad para exportar al mundo.<br /><br />
                               <b>En CryptoCampo, seguimos dedicados a optimizar cada una de las etapas, con el fin de asegurar la excelencia y el liderazgo en la industria del 
                               cannabis de alta calidad.</b>><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign2-phase3-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign2-phase3-2.jpg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
          </div>
           <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading-1">
              <button class="accordion-button collapsed accordion-question" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                FASE 4 (MAYO - JUNIO 2024)
              </button>
            </h2>
            <div id="collapse-4" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-1"
                      data-bs-parent="#step-4">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <div class="textos mb-3">
                              Durante los meses de mayo y junio de 2024, en CryptoCampo centramos nuestros esfuerzos 
                              en la cosecha de las plantas de cannabis medicinal (CBD), siguiendo un proceso meticuloso 
                              que garantiza la calidad y el rendimiento óptimo del producto final. Estos meses marcan 
                              el punto culminante de la temporada de cultivo, las cuales se desarrollaron desde 
                              noviembre-diciembre 2023 hasta el mes abril del corriente.<br />
                            </div>
                            <b class="h4 h3-green">MAYO Y JUNIO: COSECHA Y POSTCOSECHA</b><br /><br />
                            <b class="h4 h3-green">PROGRESO DEL CULTIVO</b>
                            <div class="textos mb-3">
                              En los meses previos, hemos observado un crecimiento significativo en nuestras plantas de CBD, 
                              que alcanzaron una altura superior a 1.70 metros y mostraron un desarrollo notable de flores 
                              densas y resinosas. El perfil organoléptico, típico de nuestros cultivos orgánicos, ha añadido 
                              un valor adicional a la producción actual​​​​.<br />
                            </div>
                            <div class="textos mb-3">
                              <b class="h4 h3-green">PROCESO DE COSECHA</b> <br/>
                               La cosecha se inició a finales de marzo y continuó de manera escalonada durante abril y mayo, 
                               especialmente en nuestros campos de Sauce, donde se concentra la mayor parte de nuestra producción​​. 
                               Este enfoque escalonado nos ha permitido manejar eficientemente el volumen y el espacio en las cámaras 
                               de secado, así como abordar la variabilidad en el nivel de maduración de las plantas​​​​.<br /><br />

                              🟢Cosecha Escalonada: Implementamos una estrategia de cosecha parcial y seccionada para asegurar 
                              que las plantas alcancen el punto óptimo de maduración​​.<br /><br />

                              🟢Selección de Variedades: Las genéticas cosechadas durante este periodo incluyen Ultra Pink, Fenocheese 
                              y Fedtonic, conocidas por su alta calidad y resistencia​​​​.
                            </div>
                            <div class="textos mb-3">
                              <b class="h4 h3-green">RESULTADOS DE LA COSECHA</b> <br/>
                               La producción de CBD ha sido altamente positiva, destacando tanto el éxito en la fase de floración avanzada 
                               como en el proceso de cosecha en curso. Las plantas de cannabis mostraron una densidad de cogollos excepcional, 
                               con un alto contenido de terpenos y tricomas bien desarrollados​​.<br /><br />

                               Tras la cosecha, las plantas fueron trasladadas a nuestras instalaciones de procesamiento, donde se sometieron a 
                               un secado controlado para preservar las propiedades organolépticas del producto. El proceso de trimming o manicura 
                               se realizó tanto en seco como en verde, utilizando maquinaria de última generación para asegurar una apariencia 
                               uniforme y un acabado de alta calidad​​, conforme los estándares internacionales requeridos en el mercado al cual exportamos.<br />
                            </div>
                            <b class="h4 h3-green">PROYECCIONES:</b>
                            <div class="textos mb-3">
                             En el presente informe se destaca el éxito de nuestras prácticas de cultivo y cosecha, que han dado como resultado un producto de 
                             alta calidad destinado a los mercados internacionales. CryptoCampo continúa dedicado a la excelencia y la mejora continua, 
                             asegurando que cada campaña no solo cumpla, sino que supere las expectativas de calidad y rendimiento.<br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign2-phase4-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign2-phase4-2.jpg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
          </div>
        </div>
      </div>
      <ClaimFollowCampaign2 />
    </div>
  </div>
  <div class="accordion accordion-flush mt-5 accordion-border-button" id="previous-campaigns">
    <div class="accordion-item">
      <h2 class="accordion-header" id="previous-campaigns-h2">
        <button class="accordion-button collapsed accordion-question fs-2" type="button" data-bs-toggle="collapse"
          data-bs-target="#collapse-previous-campaigns" aria-expanded="false" aria-controls="collapse-previous-campaigns">
          Campaña 1.0
        </button>
      </h2>
      <div id="collapse-previous-campaigns" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-1"
        data-bs-parent="#previous-campaigns">
        <div class="accordion-body">
          <div class="main-content">
            <div class="row container-follow-campaign" style="padding: 0 30px">
              <div class="col-9 box-nft">
                <div class="pt-5 d-flex h2 justify-content-left container-title-campaign">
                  <div>
                    CAMPAÑA 1.0:
                  </div>
                  <div class="h2 h2-black ps-2">
                    Cannabis Medicinal (cáñamo de CBD)
                  </div>
                </div>
                <div class="pt-5 d-flex h3 h3-green justify-content-left">
                  Desarrollo de la producción:
                </div>
                <div class="textos mb-3">
                  Durante los 12 meses de duración de la campaña agropecuaria, llevaremos adelante, junto a la empresa
                  CANNBIO
                  AGRO S.A.S., el cultivo en campo abierto y también en invernadero.<br />
                  En ambos casos se comienza entre noviembre y diciembre de 2022, y la producción estará destinada a
                  obtener
                  flores de cáñamo de CBD:
                </div>
                <div class="h3 h3-green mb-3">
                  <div class="mb-3">
                    <font-awesome-icon icon="fa-solid fa-check" class="me-2" />Producción de exterior <br />
                  </div>
                  <div class="mb-3">
                    <font-awesome-icon icon="fa-solid fa-check" class="me-2" />Producción en Green House (Invernadero) por
                    3 ciclos
                  </div>
                </div>
                <div class="h3 h3-green">
                  Genética de las semillas:
                </div>
                <div>
                  <ul>
                    <li>FedTonic</li>
                    <li>Superwoman</li>
                  </ul>
                </div>
                <div class="accordion accordion-flush mt-5 accordion-border-button" id="step-1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-1">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false"
                        aria-controls="collapse-1">
                        FASE 1 (Oct 2022)
                      </button>
                    </h2>
                    <div id="collapse-1" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-1"
                      data-bs-parent="#step-1">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">PREPARACIÓN DE LA TIERRA:</b>
                            <div class="textos mb-3">
                              Entre los meses de Septiembre y Octubre de 2022 el equipo de trabajo de Cannbio Agro prepara
                              la tierra de exterior e invernadero sobre la cual se sembraran las semillas y esquejes de
                              plantas madres propias según corresponda, a fin de llevar adelante la producción
                              2022-2023.<br />
                            </div>
                            <b class="h4 h3-green">GERMINACIÓN DE LAS SEMILLAS y ENRAIZAMIENTO DE ESQUEJES:</b>
                            <div class="textos mb-3">
                              Durante la primera etapa de vida de la planta, la semilla desarrolla su raíz primaria y se
                              convierte en una plántula. La germinación requiere de tres factores: oscuridad, hidratación
                              y humedad siendo estas las que activen el proceso de crecimiento inicial.<br />
                              En el caso de los clones/esquejes se enraízan para dar luego comienzo al desarrollo y
                              crecimiento inicial. Recordamos que los esquejes replican todas las propiedades de las
                              plantas madres provenientes ya que son clones de las mismas.<br />
                              La genética utilizada es: Superwoman y FedTonic, las cuales dieron un producto de una
                              calidad muy alta en la producción anterior.<br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b>mínimas de 11°C y máximas de hasta
                                20°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase1-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase1-2.jpg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3" id="step-2">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-2">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false"
                        aria-controls="collapse-2">
                        FASE 2 (Nov 2022)
                      </button>
                    </h2>
                    <div id="collapse-2" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-2"
                      data-bs-parent="#step-2">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">PREPARACIÓN DE LA TIERRA Y GERMINACIÓN:</b>
                            <div class="textos mb-3">
                              Veinticinco días antes de la siembra de las plántulas empezamos con la germinación, la cual
                              es realizada en un vivero especializado en el departamento de Rocha.<br />
                              Asimismo, es importante destacar que tanto el cultivo exterior como así también el cultivo
                              en Green House se rigen ambos por el calendario lunar, por lo que resulta una condición
                              esencial para obtener el mejor producto posible el hecho de respetar los movimientos de la
                              luna.<br />
                              Es por esa razón que los cultivos de las plántulas empezarán aproximadamente el día 18 de
                              Diciembre.
                              <br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b>mínimas de 11°C y máximas de hasta
                                20°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase2-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase2-2.jpg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" /> VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3 accordion-border-button" id="step-3">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-3">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false"
                        aria-controls="collapse-3">
                        FASE 3 (Dic 2022)
                      </button>
                    </h2>
                    <div id="collapse-3" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-3"
                      data-bs-parent="#step-3">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">SIEMBRA Y CRECIMIENTO VEGETATIVO:</b>
                            <div class="textos mb-3">
                              La producción de Cannabis de CBD a cargo de Cannbio Agro S.A.S. se encuentra en su etapa de
                              siembra final e inicio de crecimiento vegetativo. En esta instancia, de suma importancia,
                              las plantas irán desarrollando y fortaleciendo sus raíces, a fin de generar una estructura
                              sólida y resistente, no sólo a inclemencias meteorológicas, por ejemplo, sino, también, para
                              soportar el peso de los futuros frutos.<br />
                              De esta manera, durante el mes de Enero las plántulas estarán iniciando su desarrollo y
                              crecimiento de fase vegetativa. <br />
                              Conforme la visita realizada este mes por nuestro auditor especializado, se esperan grandes
                              resultados gracias a la calidad de la tierra y agua, lo que sumado al expertise de los
                              agricultores y la climatología local conforman un gran complemento para el crecimiento de
                              las mismas.<br />
                              <br />✅ Estado de las plántulas al momento de la siembra: 3 nudos, iniciando su fase de
                              crecimiento vegetativo, buena colonización de raíces en sustrato.<br />
                              <br />✅ Fase lunar en la que se llevó a cabo el proceso de siembra de plántulas: fines de
                              luna nueva e inicio de cuarto creciente. Iluminación 4 a 40%. En estas fases donde la
                              intensidad lumínica de la luna es baja y media, la savia se localiza en las partes
                              inferiores de la planta, promoviendo el desarrollo y colonización de raíces en sustrato.
                              <br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b>mínimas de 14°C y máximas de hasta
                                32°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase3-1.jpeg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase3-2.jpeg" />
                            <button type="button" class="btn w-100 p-3 fw-bold see-video"
                              @click="VerVideo('https://www.youtube.com/watch?v=qs7DWgwAg3E')"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3" id="step-4">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-4">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false"
                        aria-controls="collapse-4">
                        FASE 4 (Ene 2023)
                      </button>
                    </h2>
                    <div id="collapse-4" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-4"
                      data-bs-parent="#step-4">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">GREEN HOUSE Y CRECIMIENTO EN EXTERIOR:</b>
                            <div class="textos mb-3">
                              La producción de Cannabis de CBD a cargo de Cannbio Agro S.A.S. se encuentra en plena etapa
                              de crecimiento vegetativo.<br /><br />
                              En esta instancia, podemos observar el desarrollo de las plantas y el fortalecimiento de las
                              mismas, logrando obtener una estructura sólida y resistente a inclemencias meteorológicas
                              como, asi también, para soportar el peso de los futuros frutos que más adelante veremos en
                              su etapa de flora.<br /> <br />
                              En las próximas semanas se espera que continúe el período de crecimiento, tanto de las
                              producciones en campo exterior como así también de las que se llevan adelante en Green
                              House, por lo que se prevé que las plantas alcancen un tamaño aún más grande del que se
                              puede observar en las fotos que acompañan el presente informe.<br /><br />
                              Conforme las devoluciones obtenidas por los ingenieros agrónomos de Cannbio Agro y el equipo
                              de Master Growers, se espera una excelente producción, tanto en campo exterior como en green
                              house.<br />
                              <br />✅ Campo exterior: en producción.<br />
                              <br />✅ Green house: en producción. <br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b>mínimas de 15°C y máximas de hasta
                                30°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase4-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase4-2.jpg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" /> VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3" id="step-5">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-5">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false"
                        aria-controls="collapse-5">
                        FASE 5 (Feb 2023)
                      </button>
                    </h2>
                    <div id="collapse-5" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-5"
                      data-bs-parent="#step-5">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">CRECIMIENTO EN EXTERIOR:</b>
                            <div class="textos mb-3">
                              La producción de Cannabis de CBD realizada en exterior, a cargo de Cannbio Agro S.A.S.,
                              continúa en plena etapa de crecimiento vegetativo.<br /><br />
                              La totalidad de las plantas que se encuentran en los campos al aire libre continúan
                              experimentando un crecimiento y desarrollo óptimo, lo cual les permitirá en unos pocos meses
                              soportar el peso de los frutos que llegarán en la etapa de flora.<br /><br />
                              Es por eso que durante el mes de Marzo se continuarán llevando adelante todos los trabajos
                              de riego, nutrición y control correspondientes a los fines de seguir por el mismo camino,
                              confiando en que el producto final obtenido será de una excelente calidad.<br />
                              <br /><br />
                              <b class="h4 h3-green">GREEN HOUSE:</b><br />
                              La producción dentro de los establecimientos de Green House, a diferencia de los de
                              exterior, se realiza durante un período más corto de tiempo, el cual tiene una duración
                              aproximada de entre 3 y 4 meses.<br /><br />
                              Es por ello que actualmente nos encontramos muy prontos a finalizar la etapa de crecimiento
                              vegetativo de las flores que se encuentran allí, luego de lo cual comenzará la etapa de
                              pre-flora, la que es de suma importancia para lograr el producto final deseado.<br /><br />
                              Como se puede observar en la imágen que acompañan el presente informe, las plantas que se
                              encuentran en Green House poseen ya una estructura sólida y resistente, puesto que su
                              crecimiento se da a una velocidad más rápida que las que se encuentran al aire libre, ello
                              por la naturaleza propia de las condiciones en las cuales se desarrolla.<br /><br />
                              De acuerdo a lo que nos han informado los ingenieros agrónomos de Cannbio Agro y el equipo
                              de Master Growers, la producción está avanzando de acuerdo a lo esperado, por lo que
                              aguardamos una excelente producción al final de la campaña.<br />
                              <br />✅ Campo exterior: en etapa de crecimiento.<br />
                              <br />✅ Green house: en etapa de pre-flora. <br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b>mínimas de 18°C y máximas de hasta
                                27°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase5--1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase5--2.jpg" />
                            <button type="button" class="btn w-100 p-3 fw-bold see-video"
                              @click="VerVideo('https://youtu.be/nurVOElhhp8')"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3" id="step-6">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-6">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-6" aria-expanded="false"
                        aria-controls="collapse-6">
                        FASE 6 (Marzo 2023)
                      </button>
                    </h2>
                    <div id="collapse-6" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-6"
                      data-bs-parent="#step-6">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">CRECIMIENTO EN EXTERIOR:</b>
                            <div class="textos mb-3">
                              La producción de Cannabis de CBD llevada adelante en los campos de exterior, a cargo de la
                              empresa Cannbio Agro S.A.S., acaba de ingresar en su etapa de pre-flora.<br /><br />
                              Durante las últimas semanas transcurridas, las plantas que se encuentran al aire libre se
                              han estirado por última vez en alto y en ancho, finalizando su etapa de crecimiento en forma
                              óptima, alcanzando el tamaño deseado.<br /><br />
                              Durante el mes de Abril se espera que la totalidad de las mismas comiencen a producir sus
                              primeras flores, confiando en que el producto final obtenido será de una excelente
                              calidad.<br /> <br /><br />
                              <b class="h4 h3-green">GREEN HOUSE:</b><br />
                              La producción dentro de los establecimientos de Green House ya se encuentra en estado de
                              flora, por lo que se pueden observar los primeros cogollos en cada una de las
                              plantas.<br /><br />
                              Tal como se podrá observar en las fotografías que se acompañan al presente informe, el
                              crecimiento es uniforme entre las mismas, con estructuras tipo arbusto, bien frondosas, con
                              cogollos gordos y densos, cargados de resina y terpenos, pistilos color blancos y ambar, en
                              porcentajes similares, indicando que el momento de la cosecha se encuentra
                              próximo.<br /><br />
                              Asimismo, las flores se encuentran cargadas de aroma y se denota una importante densidad
                              definida de los cogollos, bien característico de la genética FEDTONIC utilizada en esta
                              producción.<br /><br />
                              De acuerdo a ello, desde Cryptocampo aguardamos una excelente producción al final de la
                              campaña.<br />
                              <br />✅ Campo exterior: en etapa de pre-flora<br />
                              <br />✅ Green house: en etapa de flora. <br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b>mínimas de 16°C y máximas de hasta
                                25°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase5-1.jpeg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase5-2.jpeg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" /> VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3" id="step-7">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-7">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-7" aria-expanded="false"
                        aria-controls="collapse-7">
                        FASE 7 (Abril 2023)
                      </button>
                    </h2>
                    <div id="collapse-7" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-7"
                      data-bs-parent="#step-7">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">CRECIMIENTO EN EXTERIOR Y GREEN HOUSE:</b>
                            <div class="textos mb-3">
                              La fase de cultivo exterior y la primera siembra de “Green House” han llegado a su fin,
                              dando lugar a la cosecha y tratamiento posterior. <br /><br />
                              Previo a esta etapa, las flores alcanzaron su engorde máximo y desarrollaron una marcada
                              capa de tricomas sumamente definida.<br /><br />
                              Como resultado de la misma, se obtuvo un producto final fiel a la trayectoria y experiencia
                              que tiene Cannbio para con esta genética en particular (FEDTONIC), tan vigorosa y resistente
                              a plagas.<br /><br />
                              En cuanto al aspecto visual de las flores, se nota un fruto final denso y cargado de resina,
                              cosechados en un buen punto de maduración (tricomas 15% ámbar 85% lechosos), terpenos
                              marcados en cuanto a perfil aromático y visual refiere (en especial el Beta Mirceno,
                              característico de esta cepa) el cual impacta de forma beneficiosa sobre el sistema
                              inmunológico.<br /><br />
                              En cuanto a la estructura final se observa una gran cantidad de biomasa compuesta por hojas
                              de azúcar muy definidas y cubiertas de resina.<br /><br />
                              Las plantas luego de ser cosechadas son transportadas en camiones refrigerados hasta la
                              planta de tratamiento post cosecha en la cual se procede a la poda mecánica de las hojas más
                              grandes para luego dar inicio a la fase de secado en cámaras de frío (para conservar el
                              estado óptimo de los tricomas) durante 7 a 10 días. <br /><br />
                              Cuando la materia vegetal perdió gran parte de la humedad se da inicio al curado de la
                              misma. A través de procesos mecánicos y manuales, Cannbio selecciona los cogollos ya
                              manicurados por la máquina peladora y descocadora y termina realizando una poda más precisa
                              y fina, generando uniformidad en el acabado final del producto.<br /><br />
                              Luego de la manicura final, los cogollos pasan a través de una cinta mecánica que los divide
                              en 5 tamaños hasta llegar a su empaquetado final, respetando la humedad relativa adecuada
                              para continuar la maduración y el curado del producto obtenido.<br /><br />
                              Finalmente, las flores se sellan en bolsas de gran resistencia las cuales se dejan curar en
                              oscuridad plena para evitar la degradación de los terpenos y de esta manera preservar todas
                              las propiedades del fruto mismo hasta ser exportadas a su destino respectivo.<br /><br />
                              <br />✅ Cosecha finalizada<br />
                              <br />✅ Green house: 1ra. Cosecha finalizada <br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b> mínimas de 14°C y máximas de hasta
                                22°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase6-1.jpeg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase6-2.jpeg" />
                            <button type="button"
                              class="btn w-100 p-3 fw-bold see-video button-disabled"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" /> VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3" id="step-8">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-8">
                      <button class="accordion-button collapsed accordion-question" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapse-8" aria-expanded="false"
                        aria-controls="collapse-8">
                        FASE 8 (MAYO - JUNIO 2023)
                      </button>
                    </h2>
                    <div id="collapse-8" class="accordion-collapse collapse accordion-bg" aria-labelledby="heading-8"
                      data-bs-parent="#step-8">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 col-sm-6 m-3">
                            <b class="h4 h3-green">CRECIMIENTO EN EXTERIOR Y GREEN HOUSE:</b>
                            <div class="textos mb-3">
                              Durante el mes de abril se llevó a cabo la zafra de las flores de CBD tanto en el campo
                              exterior como en los Green house ubicados en Uruguay. <br /><br />
                              A partir de este momento, se inicia una de las etapas más importantes de cuidado y trabajo
                              en las plantas de cannabis. Dado que todo el proceso es realizado de forma manual, desde el
                              mes pasado se ha destinado la mano de obra de la empresa al secado y trimmeado de la
                              cosecha.<br /><br />
                              El recorte, o trimmeo, en el que nos enfocamos en esta etapa, se realiza en seco y requiere
                              el doble de tiempo que el recorte en estado húmedo. Después de meses de trabajo en los
                              cultivos, es crucial finalizar de manera óptima toda la labor dedicada a las plantas.
                              Recortar las ramas y hojas de las plantas cosechadas, conocido como "trimming" o manicura,
                              es esencial para lograr un producto final de alta calidad con una apariencia firme y
                              uniforme.<br /><br />
                              En los meses siguientes, el campo exterior no se trabajará, y la siguiente etapa en los
                              green house (interior) podría comenzar después de la temporada de invierno. Esto se hace con
                              el objetivo de mantener una producción de alta calidad que produce un mejor producto final
                              cuando el clima es cálido.<br /><br />
                              <br />✅ Les compartimos un video sobre la última producción de CBD en el green house y la
                              calidad de la misma. <br />
                              Genética: FedTonic<br />
                            </div>
                            <div class="h3 h3-green">
                              Panorama agroclimático mensual:
                            </div>
                            <div class="textos mb-3">
                              El departamento de Rocha ha tenido temperaturas: <b> mínimas de 10°C y máximas de hasta
                                18°C.</b><img class="mb-3 rounded imgSize"
                                src="../assets/img/weatherSunshine.jpg" /><br />
                            </div>
                          </div>
                          <div class="col-12 col-sm-5 d-flex flex-column">
                            <img class="my-3 rounded" src="../assets/img/campaign1-phase7-1.jpg" />
                            <img class="mb-3 rounded" src="../assets/img/campaign1-phase7-2.jpg" />
                            <button type="button" class="btn w-100 p-3 fw-bold see-video"
                              @click="VerVideo('https://www.youtube.com/shorts/NSkgVWUJFG4')"><font-awesome-icon
                                icon="fa-solid fa-video" class="me-3" />VER VIDEO</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3 mb-5" id="step-9">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-9">
                      <button class="accordion-button accordion-question" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-9" aria-expanded="true" aria-controls="collapse-9">
                        ULTIMA FASE (OCTUBRE 2023)
                      </button>
                    </h2>
                    <div id="collapse-9" class="accordion-collapse accordion-bg" aria-labelledby="heading-9"
                      data-bs-parent="#step-9">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 p-3">
                            <b class="h4 h3-green">VENTA DE LA PRODUCCIÓN DE CANNABIS DE CBD DE LA CAMPAÑA
                              1.0:</b><br /><br />
                            <div class="textos mb-3">
                              La producción de Cannabis de CBD a cargo de Cannbio Agro S.A.S. por un total de 1520
                              kilogramos se encuentra en proceso de exportación y liquidación a favor de CryptoCampo
                              (Hydrachain LLC). <br /><br />
                              Cannbio Agro S.A.S., a través de exportaciones realizadas por la misma empresa y por parte
                              de terceras empresas, al 2 de octubre exportó y liquidó 1350 kilogramos de flores secas de
                              cannabis de CBD por cuenta y orden de CryptoCampo (Hydrachain LLC). <br /><br />
                              Actualmente se encuentra en proceso de exportación y liquidación el remanente de 170
                              kilogramos de flores secas de cannabis de CBD, lo que da un total de 1520 kilogramos de
                              propiedad de Hydrachain LLC. <br /><br />
                              <br />✅ Total de flores de CBD producidas a favor de CryptoCampo (Hydrachain LLC) y
                              exportadas en representación de la plataforma: 1520 kilogramos.<br />
                              <br />✅ Países a donde se exportó: Estados Unidos y Suiza.<br />
                            </div>
                            <br />
                            <ul class="h4 h3-green" style="padding-left: 16px;">
                              <li>
                                EXPORTACIÓN Y LIQUIDACIÓN DE 1350 KILOGRAMOS:
                              </li>
                            </ul>
                            <br />
                            <div class="textos mb-3">
                              - Partida de 910 kilos, destino Suiza: Precio final por kilo (libre de gastos y comisiones)
                              USD 315,00.- por kilo. <br /><br />
                              - Partida de 440 kilos, destino USA: Precio final por kilo (libre de gastos y comisiones)
                              USD 279,45.- por kilo. <br /><br />
                              - Ganancia obtenida: USD $409.608. <br /><br />
                            </div>
                            <ul class="h4 h3-green" style="padding-left: 16px;">
                              <li>
                                VALOR POR KILOGRAMO DE LA VENTA DEL REMANENTE (170 kg.): USD 301,00 (LIBRE DE GASTOS Y
                                COMISIONES)
                              </li>
                            </ul>
                            <br />
                            <div class="textos mb-3">
                              - Ganancia a obtener: USD $51.170. <br />
                            </div>
                            <div class="textos mb-3">
                              Conforme nos informa la empresa Cannbio Agro, y cuya documentación se encuentra a
                              disposición, la liquidación y pago de dicho remanente estará disponible a finales del mes de
                              octubre, principios del mes de noviembre, del corriente año. <br />
                            </div>
                            <br />
                            <div class="textos">
                              GANANCIA TOTAL POR VENTA DE CBD CAMPAÑA 1.0: USD $460.778
                            </div>
                            <div class="textos">
                              RENTABILIDAD TOTAL OBTENIDA: 29,79%
                            </div>
                            <div class="textos" style="background-color: #1771173d; width: max-content;">
                              RENTABILIDAD PARA EL INVERSOR: 15%
                            </div>
                            <br />
                            <ul class="h4 h3-green" style="padding-left: 16px;">
                              <li>
                                POR QUÉ LA RENTABILIDAD DEL INVERSOR ES 15%:
                              </li>
                            </ul>
                            <br />
                            <div class="textos mb-3">
                              Conforme el <a href="https://cryptocampo.app/#/whitepaper" target="_blank">Whitepaper de la
                                presente Campaña 1.0, punto 9</a>, una vez finalizada la misma, la rentabilidad final se
                              divide en partes iguales entre el usuario y la plataforma de CryptoCampo. <br /><br />
                              Cuando adquiriste el NFT, no se cobró comisión alguna e informamos que nos comprometíamos a
                              realizar la mejor producción posible para que ambos podamos obtener la más alta rentabilidad
                              de la Campaña. <br /><br />
                            </div>
                            <ul class="h4 h3-green" style="padding-left: 16px;">
                              <li>
                                DOCUMENTACIÓN A DISPOSICIÓN DEL HOLDER DE NFT DE LA CAMPAÑA 1.0:<br />
                              </li>
                            </ul>
                            <div class="textos mb-3">
                              <br /> - Liquidación por venta de Cannabis de CBD por cuenta y orden de Hydrachain LLC
                              (CRYTPYOCAMPO)<br />
                              <br /> - Comprobantes de envio a Estados Unidos a través de flete aéreo CANNCARGO (servicio
                              de flete aéreo de exportación).<br />
                              <br /> - Comprobante de servicio de exportación de OCASA<br />
                              <br /> - Contrato de Rental Agreement & Sales Commision Agreement<br />
                            </div> <br />
                            <div class="h3 mb-3" style="font-size: 17px; font-weight: bold;">
                              *La información que se encuentra testada en los presentes documentos es confidencial y no
                              nos encontramos autorizados por las empresas intervinientes a su divulgación.
                            </div>
                            <br />
                            <div class="d-flex mt-4">
                              <div class="mb-3 w-50">
                                <iframe ref="pdfViewer" width="100%" height="728" frameborder="0"></iframe>
                              </div>
                              <div class="w-50">
                                <iframe ref="pdfViewer2" width="100%" height="728" frameborder="0"></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion accordion-flush mt-3 mb-5" id="step-10">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading-10">
                      <button class="accordion-button accordion-question" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse-10" aria-expanded="true" aria-controls="collapse-10">
                        INFORMACIÓN TÉCNICA PARA EL CLAIM
                      </button>
                    </h2>
                    <div id="collapse-10" class="accordion-collapse accordion-bg" aria-labelledby="heading-10"
                      data-bs-parent="#step-10">
                      <div class="accordion-body">
                        <div class="row container-preparation-stage">
                          <div class="col-12 p-3">
                            <div class="textos mb-3">
                              Antes de realizar el claim, es importante que leas la siguiente información técnica: <br />
                              La rentabilidad obtenida de la Campaña 1.0 se pagará en USDT (técnicamente se denomina
                              BSC-USD*) en lugar de BUSD.
                            </div> <br />
                            <ul class="h4 h3-green" style="padding-left: 16px;">
                              <li>
                                ¿POR QUÉ EL CAMBIO?:<br />
                              </li>
                            </ul><br />
                            <div class="textos mb-3">
                              Paxos, socio de Binance, informó públicamente que dejara de emitir BUSD. Por esta razón,
                              queremos cuidar a nuestros holders y hacer el pago en una stablecoin vigente y con
                              operatividad en las plataformas crypto.
                            </div>
                            <br />
                            <ul class="h4 h3-green" style="padding-left: 16px;">
                              <li>
                                A SABER:<br />
                              </li>
                            </ul><br />
                            <div class="textos mb-3">
                              - Se mantiene la red blockchain BEP20 (Binance smart chain). <br /><br />
                              - Solo se cambia la stablecoin de BUSD a USDT, al estar sobre la red BEP20, el token se
                              llama BSC-USD y mantiene la paridad con el dólar 1:1. <br /><br />
                              - Si vas a claimear ingresando con tu MetaMask, es importante que agregues el token a tu
                              wallet. Y tengas gas para efectuar la transacción (BNB tenes que tener, al igual que cuando
                              compraste el NFT) <br /><br />
                              - Te dejamos el contrato de la stablecoin a utilizar para que puedas agregar el token
                              0x55d398326f99059fF775485246999027B3197955<br /><br />
                            </div>
                            <div class="h3">
                              Y recuerda claimear desde la Cuenta donde compraste el NFT.
                            </div>
                            <br />
                            <div class="textos mb-3">
                              Si queres saber más sobre Binance y la actualidad con BUSD, te invitamos a leer el link
                              oficial: <br /> <a
                                href="https://www.binance.com/es/support/announcement/binance-alienta-a-los-usuarios-a-convertir-sus-busd-a-otras-stablecoins-antes-de-febrero-de-2024-d392843e81fd4bc3a5f7e219aa01f34d"
                                target="_blank"
                                rel="noopener noreferrer">https://www.binance.com/es/support/announcement/binance-alienta-a-los-usuarios-a-convertir-sus-busd-a-otras-stablecoins-antes-de-febrero-de-2024-d392843e81fd4bc3a5f7e219aa01f34d</a>
                              <br /> <br />
                              (*) Al estar la plataforma de CryptoCampo sobre la red BEP20 (Binance smart chain), el token
                              se llama BSC-USD y mantiene la paridad con el dólar 1:1.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 box-nft-info">
                <div class="d-flex flex-column my-5">
                  <div class="campaignInfoHeader textos">
                    <div class="campaignInfoHeaderTitle">CAMPAÑA 1.0</div>
                    <div class="campaignInfoHeaderSubtitle">Cannabis medicinal (cáñamo de CBD)</div>
                  </div>
                  <div class="campaignInfo">
                    <div class='row container p-0 m-0 container-nft'>
                      <div class="col-12 px-4 py-4 card-height">
                        <video class="nft-video" id="nftVideo" width="295" height="440" autoplay loop>
                          <source src="https://cryptocampo.net/NFT/NFT.mp4" type="video/mp4">
                          Your browser does not support HTML video.
                        </video>
                      </div>
                    </div>
                    <div class="d-flex text-center justify-content-around container-nft-info">
                      <div>
                        <div class="card-info-text">
                          NFT adquiridos:
                        </div>
                        <div class="h2">
                          {{ nftBalance }}
                        </div>
                      </div>
                      <div class="vr"></div>
                      <div>
                        <div class="card-info-text">
                          Valor total(USD)
                        </div>
                        <div class="h2">
                          {{ nftPriceInERC20 * nftBalance }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <button type="button" class="btn py-4 px-5 fw-bold claim-button"
                          :disabled="processing || !canClaim || nftBalance <= 0" @click="claimTokens">CLAIM</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import useWallet from '../store/wallet';
import useCollectable from '../store/collectable';
import useERC20 from '../store/erc20';
import { utils } from 'ethers';
import { useToast } from 'vue-toastification';
import ClaimFollowCampaign2 from '../components/ClaimFollowCampaign2.vue'
export default {
  data() {
    return {
      tocAccepted: false,
      nftsToBuy: 1,
      processing: false,
      toast: null,
      videoIsOpen: false
    }
  },
  components: {
    ClaimFollowCampaign2
  },
  computed: {
    ...mapState(useWallet, ["isWalletConnected", "walletAddress"]),
    ...mapState(useCollectable, ['nftPrice', 'buyFee', 'maxSupply', 'supply', 'maxPerUser', 'nftBalance', 'canClaim']),
    ...mapState(useERC20, ['balance']),

    nftPriceInERC20() {
      if (!this.nftPrice) {
        return 0;
      }
      return utils.formatEther(this.nftPrice);
    },
  },
  mounted() {
    const iframe = this.$refs.pdfViewer;
    const pdfURL = require('url:../assets/pdf/CANNCARGO_DOCUMENTAL.pdf');
    iframe.src = pdfURL + '#toolbar=0';

    const iframe2 = this.$refs.pdfViewer2;
    const pdfURL2 = require('url:../assets/pdf/DOC_CANNBIO_CC.pdf');
    iframe2.src = pdfURL2 + '#toolbar=0';
  },
  methods: {
    ...mapActions(useCollectable, ['claim', 'getBalance']),
    async claimTokens() {
      try {
        this.processing = true;
        this.toast("Procesando...", { timeout: false, id: "claim2-toast" })
        await this.claim(this.walletAddress)
        await this.getBalance(this.walletAddress)
        this.processing = false;
        this.toast.dismiss("claim2-toast");
        this.toast.success("Transaccion realizada con exito");
      } catch (e) {
        this.processing = false;
        this.toast.dismiss("claim2-toast");
        this.toast.error("La transaccion fallo");
      }
    },
    VerVideo(url) {
      var video_url = url;
      window.open(video_url).href
    }
  },

  created() {
    this.toast = useToast();
  }
}
</script>

<style scoped>
.main-content {
  min-height: 50vh;
  background-color: white;
}

.campaign2-main-content  {
  padding: 0 16px ;
}

.h2-black {
  color: #353535
}

.h3-green {
  color: #177116
}

.accordion-question {
  background-color: #177116;
  border-top: 0.5px solid lightgray;
  color: white !important;
  font-size: 25px
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #177116 !important;
  color: white !important;

}

.accordion-button:link,
.accordion-button:visited,
.accordion-button:hover,
.accordion-button:active {
  background-color: #177116 !important;
  color: white !important;
  text-decoration: none !important;
  border-color: lightgray !important;
  box-shadow: 0px !important;

}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  color: white !important;
  box-shadow: 0 0 0 .1rem #70BB70 !important;

}

.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/img/arrow-campaign-2.svg);
  transform: rotate(90deg);
}

.accordion-button::after {
  background-image: url(../assets/img/arrow-campaign.svg);
  height: 25px;
}

.accordion-border-button {
  border-bottom: 1px solid lightgray
}

.button-disabled:not(.collapsed) {
  background-color: #E8E8E8 !important;
  color: #B4B4B4 !important;
  font-size: 25px
}


.button-disabled:link,
.button-disabled:visited,
.button-disabled:hover,
.button-disabled:active {
  background-color: #E8E8E8 !important;
  color: #B4B4B4 !important;
  text-decoration: none !important;
  border-color: lightgray !important;
  box-shadow: 0px !important;

}

.button-disabled:focus {
  z-index: 3;
  outline: 0;
  color: #B4B4B4 !important;
  box-shadow: 0 0 0 0 !important;

}

.button-disabled:not(.collapsed)::after {
  background-image: url(../assets/img/disabled-arrow-2.svg);
  transform: rotate(0deg);
}

.button-disabled::after {
  background-image: url(../assets/img/disabled-arrow-1.svg);
  height: 25px;
}

.campaignInfo {
  background: #E8E8E8;
  border-bottom: 15px solid #F2CD32;
  border-radius: 0 0 10px 10px;
}

.campaignInfoHeader {
  background-color: #157116;
  border-radius: 10px 10px 0 0;
  font-size: 20px;
  padding: 25px;
  background-image: url(../assets/img/logo-nft-header.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  background-size: 35%;
}

.campaignInfoHeaderTitle {
  color: #F2CD32;
}

.campaignInfoHeaderSubtitle {
  color: white;
  margin-top: 10px;
  font-size: 18px
}

.NFT-card {
  background: url(../assets/img/NFTbackground.jpg);
  position: relative;
  /* width: 302px; */
  /* height: 450px; */
  border-radius: 5px !important;
}

.bsc-logo-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.accordion-bg {
  background-color: #F8F8F8
}

.claim-button {
  background-color: #177116;
  color: white;
  width: 18vw;
  margin: 10px;
}

.card-height {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-align {
  align-self: center;
}

.card-info-text {
  font-size: 18px
}

.btn:disabled {
  background-color: #C8C8C8;
  color: white;
  border: 0
}

.btn {
  background-color: #177116;
  color: white
}

.container-nft {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (max-width: 1500px) {
  .container-follow-campaign {
    display: flex;
    flex-direction: column-reverse;
  }

  .box-nft {
    width: 100%;
  }

  .box-nft-info {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .container-title-campaign div {
    font-size: 24px !important;
  }

  .container-nft-info {
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .claim-button {
    width: 200px;
  }
}

@media (max-width: 376px) {
  .claim-button {
    width: 80%;
  }

  .container-title-campaign {
    flex-direction: column;
    padding-top: 1rem !important;
  }

  .container-title-campaign div {
    font-size: 16px !important;
    padding-left: 0px !important;
  }

  .container-preparation-stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
</style>
