<template>
  <div class="main-content textos">
    <div class="container-fluid pt-5 align-items-center">
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col-12 col-lg-9">
          <div class="h2">
            Campaña 2.0<br />
            <span class="h2" style="color: #353535">PRODUCCIÓN DE CANNABIS</span>
          </div>
          <div class="h3 mb-5" style="font-weight: 300;">
            ROCHA, URUGUAY
          </div>
          <p style="font-weight: bold">1-NUESTRO OBJETIVO</p>
          <div class=" mb-3 mt-3">
            <strong>CRYPTOCAMPO</strong> conecta a los usuarios de cryptomonedas con el mercado agropecuario, estableciendo un puente sin precedentes entre la tecnología financiera y el campo.
          </div>
          <div class=" mb-3">
            Nuestro objetivo es cambiar el paradigma existente, permitiendo a todo aquél que lo desee poder ser
            parte de una de las principales actividades económicas del mundo.
          </div>
          <div class=" mb-3">
            A través de la plataforma de <strong>CRYPTOCAMPO</strong> el usuario podrá participar con sus cryptos en
            mercados respaldados con activos reales y concretos relacionados con el campo.
          </div>
          <div class=" mb-3">
            De esta forma, conectamos a los usuarios con los agronegocios de forma simple, rápida y
            transparente a través de la tecnología blockchain.
          </div>
          <p style="font-weight: bold">2-TOKENIZACIÓN</p>
          <div class=" mb-3 mt-3">
            A través de la tokenización hicimos posible la digitalización de campañas agropecuarias y la
            participación de los usuarios. La tokenización de activos es la forma en que cualquier activo del
            mundo real, tangible o intangible, se digitaliza y luego se divide en partes más pequeñas que toman
            la forma de tokens.
          </div>
          <div class=" mb-3">
            Cada uno de esos tokens se encuentran registrados en la Blockchain, un registro público e
            inalterable, y su transmisión digital es inmediata, ello de forma segura a raíz de la tecnología
            utilizada.
          </div>
          <div class=" mb-3">
            La tokenización de activos es un proceso que nos ofrece múltiples ventajas, en especial, en un
            mundo cada vez más interconectado y donde la seguridad se hace cada vez más necesaria:
            <br />
            <ul>
              <li>
                Permite establecer nuevos medios de capitalización con los que podemos ampliar el acceso a
                todas aquellas personas que poseen un capital menor que hasta ahora los dejaba fuera de
                cualquier campaña agropecuaria.
              </li>
              <li>
                Permite una verdadera descentralización, al mantener el funcionamiento de esos activos
                dentro de un sistema abierto y de libre acceso para todos.  
              </li>
              <li>
                Aumenta la eficiencia en la realización de procedimientos de auditorías, transacciones,
                compras o ventas de dichos activos, ya que la tecnología blockchain ofrece herramientas
                nativas para agilizar estos procesos.
              </li>
              <li>
                Ofrece un alto nivel de transparencia, seguridad y privacidad al mantener todas las
                operaciones disponibles de forma pública.
              </li>
            </ul>
          </div>
          <p style="font-weight: bold">3-¿POR QUÉ URUGUAY?</p>
          <div class=" mb-3 mt-3">
            La palabra que mejor define a ese país es ESTABILIDAD. Uruguay se destaca en América Latina
            por ser una sociedad igualitaria, por su alto ingreso per cápita y por sus bajos niveles de desigualdad
            y pobreza. En términos relativos, su clase media es la más grande de América, y representa más del
            60% de su población.
          </div>
          <div class=" mb-3">
            El fuerte desempeño institucional en todas sus áreas, la confianza en el gobierno, la escasa
            corrupción y un enfoque político basado en consensos, así como un gran compromiso para
            fortalecer los arreglos institucionales, le da al país una base firme sobre la cual continuar creciendo
            como una de las economías más importantes de la región.
          </div>
          <div class=" mb-3">
            Si analizamos los números, el motivo resulta aún más claro. Conforme fuera informado por el
            Banco Central de Uruguay, la actividad económica en el año 2022 aumentó 4,9% respecto al año
            2021, siendo el sector agropecuario uno de los que más crecimiento reportó, con un increíble 5%. <br/>
            En cuanto a sus exportaciones, en el año 2022 se registró un aumento del 21%, impulsado en gran
            parte por el sector del Cannabis.
          </div>
          <div class=" mb-3">
            En el 2022, el PBI registró un incremento de 4,9% interanual, y se estima que para el año 2023 la
            actividad económica crecerá por lo menos en un 1,5% respecto del año pasado.
          </div>
          <div class=" mb-3">
            Asimismo, Uruguay es un país agroexportador, por lo cual la agricultura y la ganadería no solo
            están en el ADN de su población sino que, además, cuentan con numerosos programas de apoyo
            gubernamental para mejorar su producción y comercialización, transformando así a este sector en
            un aliado fundamental para su economía
          </div>
          <div class=" mb-3">
            Todos estos indicadores hacen que Uruguay sea un país ideal para llevar adelante una inversión a
            mediano y largo plazo, puesto que su estabilidad económica y política permite desarrollar negocios
            sin la preocupación de que las reglas del juego puedan cambiar de un día para el otro.
          </div>
          <p style="font-weight: bold">4-¿POR QUÉ CANNABIS?</p>
          <div class=" mb-3 mt-3">
            El mercado internacional de Cannabis se encuentra en fuerte crecimiento. Son cada vez más los
            países que permiten, no solo el consumo medicinal, sino, incluso, el recreativo, lo que ha favorecido
            enormemente el comercio internacional del sector.
          </div>
          <div class=" mb-3">
            Para citar tan sólo el caso de EE UU, a nivel federal 37 estados permiten su uso medicinal y 21 de
            ellos además han legalizado su uso recreativo, en lo que representa uno de los mercados más
            grandes del mundo.
          </div>
          <div class=" mb-3">
            Por todo ello se estima que este año la industria del Cannabis a nivel mundial comercializará
            aproximadamente US$ 51.270 millones, con una proyección para 2028 de US$ 102.870 millones,
            es decir un crecimiento de más del 100% en tan solo dos años.
          </div>
          <div class=" mb-3 d-flex justify-content-center">
            <img class="img-whitepaper-map" src="../assets/img/map.jpg" style="width: 50%;" />
          </div>
          <div class=" mb-3">
            Asimismo, la industria del Cannabis es un sector que apuesta altamente a la innovación, no solo en
            los procesos primarios de producción para optimizar los ciclos vegetales, sino también en la
            diversidad de productos y sus potenciales usos. Actualmente, a la producción de flores se suman la
            de extracto básico, la aislación de cannabinoides puros, y la elaboración de medicamentos.
          </div>
          <div class=" mb-3">
            El uso en alimentos muestra también un fuerte potencial. Además de los tres usos básicos (aceite,
            harina y semilla) el uso en alimentos de cannabinoides aislados está en auge. Esto ha dado lugar a
            un creciente número de variedades de cereales, bebidas, snacks y golosinas, entre otros.
          </div>
          <div class=" mb-3">
            Las inversiones más importantes a escala mundial vinculadas al cannabis como alimento han sido
            las relacionadas a la industria de las bebidas. Un ejemplo de estas son las de la empresa cervecera
            Corona, que invirtió US$ 3.800 millones en el sector.
          </div>
          <div class=" mb-3">
            Otros grandes segmentos en los que participa la industria son el de belleza, textil, bienestar y
            alimentación animal, entre otros.
          </div>
          <div class=" mb-3">
            Sin perjuicio de lo anterior, el mercado más grande al que se ha introducido el Cannabis es el sector
            farmacéutico, el cual impulsa en gran medida al resto de la industria. Para citar un ejemplo, la
            principal empresa a nivel internacional operando con Cannabis es la inglesa GW Pharmaceuticals,
            que en el último año fue adquirida por US$ 7.200 millones.
          </div>
          <div class=" mb-3">
            Para tomar real dimensión del crecimiento exponencial que ha tenido el Cannabis a nivel
            farmacéutico, se muestra a continuación un gráfico de la proyección de ventas globales de sus
            productos:
          </div>
          <div class=" mb-3 d-flex justify-content-center">
            <img class="img-whitepaper-graphic" src="../assets/img/graphic.jpg" style="width: 50%;" />
          </div>
          <div class=" mb-3">
            En lo que respecta a Uruguay, fue el primer país del mundo en regular la producción del cannabis,
            tanto para su uso recreativo como medicinal e industrial, a través de la ley 19.172 en diciembre de
            2013.
          </div>
          <div class=" mb-3">
            Su flujo exportador comenzó en 2019 y en 2020 se exportaron aproximadamente 10 toneladas de
            flores de Cannabis. El crecimiento de la actividad fue tal que en los años 2021 y 2022 se exportaron
            16 toneladas de flores de cáñamo por año en dicho país, posicionándose de esta manera como unode los países líderes a nivel mundial.
          </div>

          <p class="fw-bold">5-¿POR QUÉ ELEGIMOS A CANNBIO AGRO S.A.S.?</p>
          <div class=" mb-3 mt-3">
            <ol class="mb-3">
              <li>
                Experiencia: CANNBIO AGRO S.A.S. es una empresa uruguaya con <b>amplia trayectoria</b>
                en la producción de Cannabis en relación a sus competidores. Lleva más de 6 años
                trabajando en el sector, siendo hoy en día una de las principales empresas exportadoras
                de Uruguay.
              </li>
              <li>
                Sustentabilidad: Nuestros socios llevan adelante la producción de Cannabis de forma
                sustentable, llevando a la mínima expresión el uso de productos químicos y utilizando agua
                de manantial totalmente natural, lo que sin lugar a dudas se refleja en la calidad del
                producto final.
              </li>
              <li>
                Buenas prácticas agropecuarias: La empresa que elegimos desarrolla su producción bajo
                estrictas normas de calidad, cuidado y seguridad que la han llevado a estar certificada por
                sus buenas prácticas agropecuarias por la LSQA S.A.
              </li>
              <li>
                Calidad humana: En CryptoCampo creemos que el éxito de cualquier proyecto está
                determinado por la calidad humana de sus equipos. Esta es una de las razones principales
                por las que elegimos a CANNBIO AGRO S.A.S., ya que más de medio centenar de
                personas conforman su equipo y todas ellas se caracterizan por su compromiso con la
                producción que llevan adelante, y con profundo orgullo por pertenecer a esta gran familia
                que es Cannbio Agro S.A.S.
              </li>
              <li>
                  Resultados: la calidad productiva de esa empresa la ha llevado a ser reconocida
                  mundialmente por lograr uno de los mejores productos de toda la industria del cannabis.
                  Sus resultados han sido cada vez más sobresalientes.
              </li>
              <li>
                Experiencia en campañas anteriores: Cannbio Agro S.A.S. ha sido socia de CryptoCampo
                durante la campaña 2022/2023 cumpliendo con excelencia sus función productiva y de
                comercialización del producto final, acompañándonos en el éxito de la campaña 1.0. En
                dicha campaña los inversores obtuvieron una ganancia del 15% sobre sus inversiones de
                la mano de la devolución del capital invertido.
              </li>
            </ol>
            <div class=" mb-3">
                  Esto resulta fundamental a la hora de la comercialización de estos productos, ya que al
                  tratarse de un “speciality” su precio está directamente relacionado con la calidad del
                  mismo.
                </div>
                <div class=" mb-3">
                  De esta forma, Cannbio Agro S.A.S. ha logrado la comercialización de su producción al
                  nivel de precio más alto del mercado
                </div>
            <div class=" mb-3">
              <strong>-Cantidad de hectáreas disponibles para su producción:</strong>
              <ol>
                <li>
                  <div class="mb-2">
                    A campo abierto: 75 hectáreas de campo en Rocha, Uruguay
                  </div>
                  <div class="mb-2">
                    En la campaña 2022/2023 sólo se han siembrado entre 6 y 7 hectáreas, ello debido a que es un
                    cultivo que lleva mucha mano de obra y dedicación, por lo que su tratamiento a lo largo del ciclo
                    es manual.
                  </div>
                  <div class="mb-2">
                    A fin de obtener la mejor flor y no descuidar los cultivos existentes, se van a ir abarcando hectáreas
                    de manera paulatina.
                  </div>
                  <div class="mb-2">
                    Es importante destacar que la empresa lleva adelante todo el ciclo de producción de cannabis,
                    desde el tratamiento de sus tierras, germinación y cultivo, incluyendo también la cosecha,
                    tratamiento post cosecha y comercialización.
                  </div>
                </li>
              </ol>
            </div>
            <div class=" mb-3">
              <strong>-Toneladas producidas en 2022/2023:</strong>
              <ol>
                <li>
                  <div class="mb-2">
                    2022/2023:
                  </div>
                  <div class="mb-2">
                    En Uruguay, la siembra se realiza en los meses de calor, es decir entre Noviembre y Diciembre.
                    Ese año, en lo que fue la producción a campo abierto, se sembraron entre 6 y 7 hectáreas y se
                    consiguió obtener 5.500 kg de flores de cáñamo.
                  </div>
                  <div class="img-previous-campaign-container">
                    <img src="../assets/img/campaign2-whitepaper-img1.png" alt="Imagen de la cosecha" class="img-previous-campaign" />
                    <img src="../assets/img/campaign2-whitepaper-img2.png" alt="Imagen de la cosecha" class="img-previous-campaign" />
                  </div>
                </li>
              </ol>
            </div>

            <p class="fw-bold">6-¿CÓMO PUEDO SER PARTE DE LA CAMPAÑA?</p>
            <div class=" mb-3 mt-3">
              <div class="mb-3">
                Con tan solo USD 100, el usuario podrá adquirir un NFT que representará su participación en la
                campaña agropecuaria que se llevará adelante, la cual tendrá un plazo de duración de 12 meses.
              </div>
              <div class="mb-3">
                En esta campaña 2.0, la totalidad de NFTs que estarán disponibles será de 5.000 unidades, de los
                cuales los usuarios podrán adquirir una cantidad de hasta 3.000 ya que los 2.000 restantes han sido
                adquiridos por inversores privados que nos acompañarán en esta nueva campaña. 
              </div>
              <div class="mb-3">
                Si no sabes cómo comprar un NFT no te preocupes, en la web tendrás un video instructivo que te
                explicará paso a paso como hacerlo. Además, los días durante los cuales se lleve adelante el minteo
                pondremos un equipo técnico a disposición de todos nuestros usuarios por cualquier duda que
                puedan tener.
              </div>
              <img src="../assets/img/campaign2-whitepaper-how-invest.png" class="how-invest-img" alt="Como invertir en Cryptocampo"  />
              <div class="text-decoration-underline">SISTEMA DE PROTECCIÓN DE LOS NFT</div>
                <div>
                  En CryptoCampo nuestros usuarios son lo más importante y es por eso que decidimos cuidarlos al diseñar
                  un <b>SISTEMA DE PROTECCIÓN DE LOS NFT.</b><br />
                  <b>¿Cómo funciona?</b> Desarrollamos un Smart Contract único en el ecosistema, donde impedimos que los
                  NFT de nuestros usuarios puedan ser robados, ya que, una vez minteados, no se pueden transferir a otra
                  wallet.<br />
                  <b>Eso significa que tu NFT es INTRANSFERIBLE.</b><br />
                  De esta manera, te protegemos de cualquier ataque que puedas sufrir y vas a estar tranquilo y seguro que
                  tu NFT está cuidado hasta el momento en que lo puedas claimear.
                </div>
              <div class="mb-3">
                <div class="text-decoration-underline">SIN TOKEN NATIVO</div>
                <div class="mb-3">
                  A los fines de proteger a nuestros usuarios de la volatilidad de las cryptomonedas, en
                  <strong>CRYPTOCAMPO</strong> decidimos no utilizar token nativo, por lo que la totalidad de las operaciones
                  que se realizarán en la plataforma serán en USDT (BEP20 - red Binance Smart Chain),
                </div>
                <div class="text-decoration-underline">MONTO DE LA CAMPAÑA 2.0</div>
                <div class="mb-3">
                  Para esta primera campaña estipulamos como monto total la suma de USD 500.000. La plataforma
                  no recibirá ni un dólar más. ¿Por qué? Porque con esa suma de dinero se puede llevar adelante una
                  producción anual en forma controlada, prolija y prestando atención a cada detalle que requiera la
                  misma.
                  <br>
                  ¿Qué sucede si no se venden la totalidad de los NFT? Simplemente se desarrollará la campaña con
                  los fondos recaudados. En esta oportunidad CryptoCampo se ha asociado con Cannbio Agro S.A.S.
                  en la producción de su campaña 2023/2024. De esta forma, la cantidad de hectáreas producidas se
                  encuentra sujeta a los fondos recaudados durante el minteo de los NFT de la campaña 2.0.
                </div>
              
              </div>
            </div>

            <p class="fw-bold">7-DESARROLLO DE LA PRODUCCIÓN</p>
            <div class="my-3">
              Durante los 12 meses de duración de la campaña agropecuaria, llevaremos adelante junto a la
              empresa CANNBIO AGRO S.A.S. el cultivo de entre 6 a 15 hectáreas en campo abierto. Se
              comienza la siembra entre noviembre y diciembre de 2023, y la producción estará destinada a
              obtener flores de cáñamo de CBD:
            </div>
            <div class="mb-3">
              La siembra se lleva adelante en los campos propiedad de la empresa CANNBIO AGRO S.A.S. y
              sus cultivadores anexos, la cual se encuentra inscripta en el Registro Único de Operadores delMinisterio de Ganadería, Agricultura y Pesca de Uruguay.
              <br>
              Los cultivos y planta de procesamiento post cosecha están ubicados en el departamento de Rocha,
              República Oriental del Uruguay, donde las condiciones climáticas y de seguridad son ideales para la
              producción sostenible de cannabis medicinal (cáñamo de CBD). <br/>
              Al tratarse de un cultivo de exterior, no solamente contamos con la luz natural del sol y la excelente
              tierra para sembrar, sino que también tenemos recursos de agua natural de manantial para los
              sistemas de riego de los campos, lo que crea de esta manera las condiciones ideales para obtener un
              producto de excelente calidad.
              <br>
              Una de las razones por las cuales elegimos este tipo de cultivo es porque posee una baja huella de
              carbono. Al cultivar bajo el sol se usa el medio ambiente natural como combustible de crecimiento,
              por lo que no se requiere luces de alta intensidad artificiales. Comparado con el Cannabis de
              interiores, las plantas cultivadas bajo el sol requieren típicamente menos mejoras en el suelo,
              fertilizantes, pesticidas y fungicidas. Asimismo, también permite una ventilación y circulación de
              aire natural, dado que no se necesitan ventiladores eléctricos.
              <br>
              Como muestra de nuestro compromiso social con el ecosistema, decidimos con CANNBIO AGRO
              implementar una política de buenas prácticas agrícolas y ello fue reconocido por la LSQA S.A. a
              través del Certificado de Buenas Prácticas Agrícolas otorgado a la empresa.
              <br>
              Gracias al uso de la tecnología, podemos garantizar que se cumplen todos los estándares
              internacionales en todo nuestro proceso de producción y comercialización, lo que asegura un
              producto único en el mercado.
            </div>
            <div class="mb-2 fw-bold">-Genética de las semillas a utilizar:</div>
            <p>La genética de las semillas va cambiando año a año.</p>
            <div class="mb-3">
              En la campaña l año 2023/2024 se utilizará la genética FedTonic principalmente, pudiendo
              incorporarse alguna genética especialmente solicitada por los clientes de Cannbio Agro S.A.S. 
            </div>
            <div class="mb-3"><b>-Tipo de riego: </b>El sistema de riego utilizado por CANNBIO AGRO es el computarizado, donde
              cada cantero tiene un sistema de goteo doble y cada planta recibe agua, fertilizantes y nutrientes
              orgánicos por raíz a través de este sistema. Todo lo que nutre a la planta por raíz se canaliza por
              ese goteo y mientras que la labor de foliar se realiza a mano.
            </div>
            <div class="mb-2"><b>
              -Tipo de secado: </b>El secado se realiza en la Planta de Procesamiento Industrial post cosecha
              perteneciente a la empresa CANNBIO AGRO, ubicada en La Paloma. ¿Por qué esto es sumamente importante?
              Porque el secado es una de las etapas más importantes del proceso de producción y los centros que realizan 
              el mismo son muy pocos en todo Uruguay, razón por la cual la mayoría de las empresas productoras
              deben sacar un turno y esperar al mismo para recién proceder al secado, lo que pone en peligro su producción. 
              Esto jamás sucede con CANNBIO AGRO, puesto que al tener su propio lugar de secado puede realizarlo en el momento del año que
              prefiera, ello sin peligro alguno para el producto final.
            </div>
            <div class="mb-3">
              En cuanto a su forma, se realiza en frío y colgado por 6 a 7 días en cámara. Es importante
              destacar que no se realiza en cajones porque ello dañaría al producto.
            </div>
            <div class="mb-3">
              <b>-Máquinas que se utilizan en la planta de tratamiento post cosecha: </b>Toda la maquinaria
              utilizada es propiedad de CANNBIO AGRO, son de última generación, marca Master, y de origen
              español:
            </div>
            <ul class="mb-3" style="list-style: disc;">
              <li>Trimmeado en seco: MT Dry 800 es una trimmer diseñada para el procesado de las flores de Cannabis en
                seco <a target="_blank" class="link-color"
                  href="https://masterproducts.es/trimmer/mt-dry-800">(https://masterproducts.es/trimmer/mt-dry-800)</a>
              </li>
              <li>Trimmeado en verde: MASTER TRIMMER MT STANDARD 50 & 75<a target="_blank" class="link-color"
                  href="https://masterproducts.es/trimmer/mt-standard">(https://masterproducts.es/trimmer/mt-standard)</a>
                para trimmeado de flores en verde.</li>
              <li>Descogolladoras: MASTER BUCKER MB BUCKER 500, una máquina descogolladora para grandes producciones <a
                  target="_blank" class="link-color"
                  href="https://masterproducts.es/bucker/mb-bucker-500">(https://masterproducts.es/bucker/mb-bucker-500)</a>.
              </li>
              <li>Clasificadora: A través de la MASTER SORTER 500 se clasifican las flores de cannabis por tamaño y cáñamo
                de última generación, diseñada para integrarse perfectamente en el flujo de trabajo de la línea
                automatizada post-cosecha o usarla individualemente <a target="_blank" class="link-color"
                  href="https://masterproducts.es/sorter/master-sorter-500">(https://masterproducts.es/sorter/master-sorter-500)</a>
              </li>
            </ul>
            <div>Al asociarnos con una empresa experimentada y exitosa del sector, cumplimos los siguientes objetivos:
            </div>
            <ul class="mb-3" style="list-style: none;">
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Un modelo de negocio de
                cultivo y distribución de cannabis eficiente y eficaz.</li>
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Productos de cannabis
                medicinal de alta calidad fabricados para satisfacer la creciente demanda en los mercados de exportación.
              </li>
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />La apertura progresiva de
                nuevos mercados y negocios vinculados al cannabis</li>
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Una producción sostenible
                que colabore con el medio ambiente</li>
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Múltiples cosechas
                garantizadas durante todo el plazo de la campaña</li>
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Reducir los riesgos y
                maximizar la eficiencia</li>
            </ul>

            <p class="fw-bold my-3">8-EXPERIENCIA DEL USUARIO</p>
            <div>
              ¿Qué sucede mientras llevamos adelante la producción? <b>Vas a vivir una experiencia de
                usuario increíble.</b>
              <br>
              En <b>CRYPTOCAMPO</b> pensamos que los usuarios no deben ser meros inversores, sino los protagonistas de
              cada campaña. Es por ello que el usuario tendrá a su disposición las siguientes herramientas en nuestra
              plataforma:
            </div>
            <ul class="mb-3" style="list-style: none;">
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Información actualizada del
                mercado</li>
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Informes de estado y
                evolución de cada campaña agropecuaria</li>
              <li class="my-1"><font-awesome-icon icon="fa-solid fa-check" class="me-2 mt-1" />Imágenes y localización de
                la misma</li>
            </ul>
            <div class="mb-3">De esta forma, todos nuestros usuarios van a poder conocer y acceder en tiempo real a la
              campaña agropecuaria de la que formen parte, sin importar en que lugar del mundo estén.</div>

            <p class="fw-bold my-3">9-¿QUÉ SUCEDE CUANDO SE TERMINA LA CAMPAÑA AGROPECUARIA?</p>
            <div class="mb-3">
              Una vez finalizada la misma, informaremos a nuestros usuarios los resultados de la comercialización del
              total de toneladas producidas, acompañando las constancias correspondientes a los fines de asegurar una
              total transparencia para con ellos.
              <br>
              Luego, cada uno de los usuarios podrá obtener las ganancias correspondientes en proporción a la cantidad de
              NFT que posean. ¿De qué forma? En la plataforma van a poder hacer “click” en la opción de “claim”, e
              inmediatamente se les entregará el capital inicial más la rentabilidad obtenida, así como también un
              exclusivo <b>POAP</b> que servirá como recordatorio de su experiencia en la Campaña 2.0
            </div>
            <div class="mb-3">¿
              <b class="text-decoration-underline">Qué es lo que cobramos desde Cryptocampo?</b>
              <br>
              Para mostrar nuestro compromiso, nosotros compartimos el resultado de la campaña con vos. ¿Eso qué quiere
              decir? que CryptoCampo no va a cobrar comisión alguna al adquirir el NFT, sino que nos comprometemos a
              realizar la mejor producción posible para que ambos podamos obtener la más alta rentabilidad de la Campaña.
              Creemos en nuestro trabajo y compromiso con el usuario, y esta es la forma en la cual te lo demostramos. <br/>
              De esta forma, la rentabilidad final se va a dividir en partes iguales entre el usuario y la plataforma de
              CryptoCampo.
            </div>

            <p class="fw-bold mb-3">10-RENTABILIDAD HISTÓRICA</p>
            <div class="mb-3">
              Desde Cryptocampo no podemos asegurarte una rentabilidad determinada, ya que eso depende de muchos factores
              que son imposibles de predecir (en particular los precios del mercado de Cannabis de CBD al momento de la
              comercialización del producto), pero lo que sí te podemos asegurar es que <b>realizaremos todo el esfuerzo
              necesario para que juntos obtengamos la mayor rentabilidad posible.</b>
              <br>
              De acuerdo a la rentabilidad histórica que ha tenido la exportación de flores de cáñamo de CBD en las
              últimas operaciones llevadas adelante por la empresa CANNBIO AGRO, hemos definido tres escenarios posibles
              de rentabilidad esperada:
            </div>

            <div class="mb-3 fw-bold">
              RENTABILIDAD BAJA
              <br>
              <img class="img-whitepaper" src="../assets/img/whitepaper2-img1.png" alt="Rentabilidad baja">
            </div>
            <div class="mb-3 fw-bold">
              RENTABILIDAD MEDIA
              <br>
              <img class="img-whitepaper" src="../assets/img/whitepaper2-img2.png" alt="Rentabilidad media">
            </div>
            <div class="mb-3 fw-bold">
              RENTABILIDAD ALTA
              <br>
              <img class="img-whitepaper" src="../assets/img/whitepaper2-img3.png" alt="Rentabilidad alta">
            </div>

            <p class="fw-bold my-3">11-BENEFICIOS DE SER PARTE DE CRYPTOCAMPO</p>
            <ul class="mb-3" style="list-style: disc;">
              <li>1. Podes realizar todas tus operaciones desde la plataforma, sin la necesidad de perder tiempo ni abonar
                costos administrativos innecesarios.</li>
              <li>2. Te permitimos formar parte de una campaña agropecuaria por un monto inicial mínimo</li>
              <li>3. Estamos asociados a una empresa de primer nivel mundial, legalmente constituida en un país que tiene
                permiso de producción de cannabis medicinal y que, además, ha obtenido el Certificado de Buenas Prácticas
                Agrícolas otorgado por la LSQA S.A.</li>
              <li>4. Juntos produciremos flores de cáñamo de CBD de primera calidad con fines de exportación.</li>
              <li>5. El cannabis posee un crecimiento exponencial en mercados como el de alimentos, bebidas, industria
                textil y productos farmacéuticos.</li>
              <li>6. Formarás parte de una comunidad única, tendrás acceso exclusivo a eventos de CryptoCampo y a los
                sorteos que realizaremos de nuestro merchandising.</li>
            </ul>

            <p class="fw-bold my-3">12-TECNOLOGÍA WEB3.0 DE LA PLATAFORMA</p>
            <div class="">
              Para llevar adelante el desarrollo del SmartContract de la plataforma, utilizamos para la
              creación de cada uno de nuestros tokens las librerías de Open Zeppelin en el contrato:
            </div>
            <ul class="mb-3" style="list-style: disc;">
              <li>Protocolo ERC 721</li>
              <li>Protocolo ERC 721 enumerable</li>
              <li>Ownable</li>
              <li>ReentrancyGuard</li>
              <li>Counters</li>
            </ul>
            <div class="mb-3">
              Asimismo, la plataforma de CryptoCampo fue desarrollada sobre la red de BNB Chain (la cual
              es la evolución de Binance Smart Chain).
            </div>
            <div class="mb-3 fw-bold">
              <b>En nuestra plataforma utilizamos el potencial y la seguridad de la tecnología
              blockchain, la cual le otorga transparencia y confiabilidad a todas las acciones realizadas.</b>
            </div>
            <ul style="list-style: disc;">
              <li>Wallets compatibles con la BNB Chain</li>
            </ul>
            <div>
              Cualquier wallet para Ethereum que tenga la posibilidad de modificar su configuración para aceptar otras
              redes será compatible con la BNB Chain. Tené en cuenta que,
              <b>para pagar las tarifas de red al momento de comprar el NFT, será necesario que tengas cierta cantidad de
                la criptomoneda BNB en tu wallet (unos USD 10 en BNB, por ejemplo, pueden servirte para hacer unas 20 o 25
                transacciones).</b>
            </div>
            <div class="mb-3">
              Si vas a utilizar aplicaciones descentralizadas como Metamask, la misma no viene configurada
              por defecto para la BNB Chain pero es algo que puede hacerse muy fácilmente.
            </div>
            <div class="mb-3">
              Esta blockchain también tiene su wallet oficial: <b>Binance Wallet</b>. Funciona, al igual
              que Metamask, como una extensión para navegador. La diferencia es que ya desde su configuración inicial
              soporta la BNB Chain.
            </div>
            <div>
              <p class="fw-bold my-3">13-Campaña 1.0-ÉXITOS Y RESULTADOS-</p>
              <div class="d-flex flex-column p-2">
                <p>
                  En el 2022/2023 Cryptocampo lanzó su campaña 1.0., ¡siendo la misma todo un éxito!
                </p>  
                  <img class="img-profit"  src="../assets/img/whitepaper-profits.jpeg" />
                </div>
                <p>
                  <b>La campaña se baso en la producción de 1520 kilos de cannabis de CBD producida y
                  comercializada por Cannbio Agro S.A.S. y generó una rentabilidad del 29,79% en el plazo de
                  1 año.</b>
                </p>
                <p>
                  Dicha campaña <b>le ha generado una ganancia del 15% sobre su inversión</b> a los holders de los
                  NFTs de la campaña 1.0, quienes han obtenido <b>la devolución del capital invertido más la
                  ganancia <u>¡15 días antes de la fecha de pago establecida!</u></b>
                </p>
                <p>
                  La experiencia obtenida durante la Campaña 1.0 validó la excelencia institucional y técnica, el
                  compromiso profesional y la calidad humana sobresaliente que caracterizan a Cannbio Agro S.A.S..
                  Esto confirma su posición como una empresa líder en el mercado del cannabis de Uruguay y a nivel
                  internacional.
                </p>
                <p>
                  En la Campaña 2.0, hemos fortalecido nuestra colaboración con esta destacada empresa y hemos
                  diseñado una nueva estrategia basada en cimientos sólidos, con el objetivo de proporcionar a
                  nuestros usuarios la mejor experiencia posible como inversores en activos tokenizados.
                </p>
            </div>
            <div>
              <p class="fw-bold my-3">14-FOUNDERS</p>
              <div class="d-flex justify-content-around pt-5 px-5">
                  <a class="d-flex flex-column p-2" target="_blank" href="https://ar.linkedin.com/in/paula-arenzo-b55664189">
                    <img class="founders" src="../assets/img/Paula-2.jpg" />
                    <button class="btn btn-primary founder-name mt-1">Paula</button>
                  </a>
                  <a class="d-flex flex-column p-2" target="_blank" href="https://www.linkedin.com/in/bruno-arenzo-813051187/">
                    <img class="founders" src="../assets/img/Bruno-1.jpg" />
                    <button class="btn btn-primary founder-name mt-1">Bruno</button>
                  </a>
              </div>
              <div class="d-flex justify-content-around pt-5 px-5">
                  <a class="d-flex flex-column p-2" target="_blank" href="https://www.linkedin.com/in/federico-celico-24211782/" >
                    <img class="founders" src="../assets/img/Federico.jpg" />
                    <button class="btn btn-primary founder-name mt-1">Federico</button>
                  </a>
                  <a class="d-flex flex-column p-2" target="_blank" href="https://www.linkedin.com/in/m%C3%A1ximo-g%C3%A1ndara-9028a342/">
                    <img class="founders" src="../assets/img/Maximo.jpg" />
                    <button class="btn btn-primary founder-name mt-1">Máximo</button>
                  </a>
              </div>
            </div>
            <p style="font-size: 3rem; font-weight: bold; color:#157116; text-align: center; margin-top: 100px">ENCONTRANOS EN LAS REDES</p>
            <div class="socialmedia-icons-container">
              <a style="color: #a4338a" href="https://www.instagram.com/cryptocamponet/" target="_blank" class="socialmedia-link">
                <IgLogo color="#a4338a" />
                Instagram
              </a>  
              <a style="color: #5CB0E4" href="https://twitter.com/CryptocampoNet" target="_blank" class="socialmedia-link">
                <TwitterLogo color="#5CB0E4" />
                Twitter
              </a>  
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3" style="position: realtive;">
          <div class="index-whitepaper">
            <div style="font-weight: bold">
              <span class="h2 m-4">INDICE:</span>
              <div style="font-weight: normal" class="mt-3 ms-3">
                <p class="m-2">1. Nuestro Objetivo</p>
                <p class="m-2">2. Tokenización</p>
                <p class="m-2">3. ¿Por qué Uruguay?</p>
                <p class="m-2">4. ¿Por qué Cannabis?</p>
                <p class="m-2">5. ¿Por qué Cannbio Agro S.A.S.?</p>
                <p class="m-2">6. ¿Cómo puedo ser parte de la campaña?</p>
                <p class="m-2">7. Desarrollo de la producción</p>
                <p class="m-2">8. Experiencia de usuario</p>
                <p class="m-2">9. ¿Qué sucede cuando se termina la campaña agropecuaria?</p>
                <p class="m-2">10. Rentabilidad Histórica</p>
                <p class="m-2">11. Beneficios de ser parte de Cryptocampo</p>
                <p class="m-2">12. Tecnología WEB3.0 de la plataforma</p>
                <p class="m-2">13. Campaña 1.0- éxito y resultados-</p>
                <p class="m-2">14. Founders</p>
              </div>
            </div>
            <button class="button-pdf mt-3" style="border: 0; border: none; background: none; width: -webkit-fill-available; margin: 0 20px;">
              <a :href="require('url:../assets/pdf/whitepaper_campaign_2.pdf')"
                download="terminos-y-condiciones-de-servicio.pdf"
                target="_blank"
                style="width: 100%; height: 151px; background-color: #157116;"
                class="mb-5 d-flex align-items-center justify-content-center flex-column rounded btn-pdf"
              >
                <font-awesome-icon icon="fa-solid fa-download" class="h1 white" />
                <div class="h3 download">Descargar versión</div>
                <div class="h5 pdf">".pdf"</div>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Divider from '../components/Divider.vue';
import IgLogo from '../components/icons/igLogo.vue'
import TwitterLogo from '../components/icons/twitterLogo.vue'

export default {
  data() {
    return {
      igIconHover: false,
      twitterIconHover: false
    }
  },
  components: {
    Divider,
    IgLogo,
    TwitterLogo
  },
}
</script>

<style scoped>
* {
  font-size: Lato !important;
}

.accordion-question {
  background-color: #177116;
  border-top: 0.5px solid lightgray;
  color: white !important;
  font-size: 2rem;
  font-weight: bold;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/img/arrow-campaign-2.svg);
  transform: rotate(90deg);
}

.accordion-button::after {
  background-image: url(../assets/img/arrow-campaign.svg);
  height: 25px;
}

.accordion-border-button {
  border-bottom: 1px solid lightgray
}

.index-whitepaper {
  position: sticky;
  top: 80px;
}

.main-content {
  min-height: 50vh;
  background-color: white;
}

.download {
  font-weight: 400;
  color: #F2CD32
}

.img-previous-campaign-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.img-previous-campaign {
  width: 45%;
  object-fit: cover;
}

.how-invest-img {
  width: 100%;
  margin: 16px 0;
}

.pdf {
  color: white;
  font-weight: lighter
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.founders {
  width: 200px;
  border-radius: 100%;
  border: 10px solid #177116
}

.founder-name {
  background-color: #177116;
  border: 0;
}

a:hover .fa-download {
  color: #71BB71
}

.link-color {
  color: rgb(40, 103, 197);
}

.socialmedia-icons-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 48px;
}

.socialmedia-link {
  transition: all 0.2s;
}

.socialmedia-link svg {
  width: 35px;
  height: 35px;
  fill: rgb(164, 51, 138);
  transition: all 0.2s;
  color: rgb(164, 51, 138);
}

.iglogo {
  background-color: rgb(164, 51, 138);
  fill: rgb(164, 51, 138);
  color: rgb(164, 51, 138);
}

.img-profit {
  width: 50%;
  border: 1px solid #177116
}

@media (max-width: 992px) {
  .index-whitepaper {
    position: initial;
  }

  .btn-pdf {
    width: 300px !important;
  }

  .img-whitepaper-map {
    width: 100% !important;
  }

  .img-whitepaper-graphic {
    width: 100% !important;
  }

  .img-whitepaper {
    width: 100% !important;
  }

  .img-previous-campaign-container {
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }
  .img-previous-campaign {
    width: 100%;
  }
  
}</style>