<template>
  <div class="main-content">
    <div class="h1">Términos y condiciones de servicio</div>
    <ol>
      <li class="fw-bold">Introducción</li>
      <div class="textos mb-3">
        Bienvenido a CRYPTOCAMPO, propiedad y operación de HYDRA CHAIN LLC (en adelante “CryptoCampo”, "nosotros", "nos"
        o "nuestro", “plataforma”).
      </div>
      <div class="textos mb-3">
        Esta página establece las Condiciones Generales que regulan el uso de los contenidos y servicios que integran el
        portal de internet: www.cryptocampo.app, (en adelante el "Sitio" o “Plataforma”) y otros portales de Internet
        propiedad directa o indirecta de “HYDRA CHAIN LLC”, cuyo nombre comercial es “CRYPTOCAMPO” (en adelante
        indistintamente el “Titular” o la "Sociedad").
      </div>
      <div class="textos mb-3">
        Estos Términos y Condiciones de servicio ("Términos", "Términos y Condiciones" o “TyC”) rigen su acceso y uso de
        los sitios web de CryptoCampo, nuestras API, la aplicación móvil (la "Aplicación") y cualquier otro software,
        herramientas, características o funcionalidades proporcionadas en o en conexión con nuestros servicios;
        incluido, entre otros, el uso de nuestros servicios para ver, explorar y adquirir NFT. "NFT" en estos Términos
        significa un TOKEN NO FUNGIBLE o un elemento digital similar implementado en una cadena de bloques o blockchain,
        que utiliza contratos inteligentes para vincularse o asociarse con cierto contenido o datos.
      </div>
      <div class="textos mb-3">
        A los efectos de estos Términos, "usuario", "usted" y "su" se refieren a usted como usuario del Servicio. Si
        utiliza el Servicio en nombre de una empresa u otra entidad, "usted" lo incluye a usted y a esa entidad, y
        declara y garantiza que (a) es un representante autorizado de la entidad con la autoridad para obligar a la
        entidad a estos Términos y (b) usted acepta estos Términos en nombre de la entidad.
      </div>
      <div class="textos mb-3 fw-bold">
        POR FAVOR LEA ESTOS TÉRMINOS DE SERVICIO CUIDADOSAMENTE YA QUE CONTIENEN INFORMACIÓN IMPORTANTE Y AFECTAN SUS
        DERECHOS LEGALES. COMO SE DESCRIBE EN LA SECCIÓN 15 CONTINUACIÓN, INCLUYEN UN ACUERDO DE ARBITRAJE OBLIGATORIO Y
        UNA RENUNCIA A UNA DEMANDA COLECTIVA QUE (CON EXCEPCIONES LIMITADAS) REQUIEREN QUE CUALQUIER DISPUTA ENTRE
        NOSOTROS SE RESUELVA MEDIANTE ARBITRAJE INDIVIDUAL Y NO POR UN JUEZ O JURADO EN UN TRIBUNAL.
      </div>
      <div class="textos mb-3 fw-bold">
        AL HACER CLIC PARA ACEPTAR Y/O UTILIZAR NUESTRO SERVICIO, USTED ACEPTA ESTAR OBLIGADO POR ESTOS TÉRMINOS Y TODOS
        LOS TÉRMINOS INCORPORADOS AQUÍ POR REFERENCIA. SI NO ACEPTA ESTOS TÉRMINOS, NO PODRÁ ACCEDER NI UTILIZAR EL
        SERVICIO.
      </div>
      <div class="textos mb-3">
        CryptoCampo no es un proveedor de carteras, bolsa, corredor, institución financiera, empresa de servicios
        financieros ni acreedor. CryptoCampo proporciona un servicio web 3.0 que ayuda a los usuarios a adquirir NFT
        (tokens no fungibles) disponibles en cadenas de bloques públicas que va a representar su participación en la
        campaña agropecuaria ofrecida por la plataforma. No ejecutamos ni efectuamos compras, ni transferencias de NFT.
        Para usar nuestro Servicio, debe usar una wallet o billetera de terceros que le permita realizar transacciones
        en blockchains, como es el caso de Metamask u otra/s ofrecidas por la plataforma al momento de acceder o
        conectarse al sitio www.cryptocampo.app.
      </div>
      <div class="textos mb-3">
        CryptoCampo se reserva el derecho de cambiar o modificar estos Términos en cualquier momento y a nuestro
        exclusivo criterio. Si realizamos cambios sustanciales a estos Términos, haremos todos los esfuerzos razonables
        para notificar dichos cambios, por ejemplo, proporcionando un aviso a través del Servicio o actualizando la
        fecha de "Última actualización" al comienzo de estos Términos. Al continuar accediendo o utilizando el Servicio,
        usted confirma su aceptación de los Términos revisados ​​y todos los términos incorporados en ellos por
        referencia a partir de la fecha de actualización de estos Términos. Es su exclusiva responsabilidad revisar los
        Términos para ver dichos cambios y asegurarse de que comprende los términos y condiciones que se aplican cuando
        accede o utiliza el Servicio. Es por esa razón que le indicamos al usuario que: a. Deberá leer atenta y
        comprensivamente los presentes Términos y Condiciones cada vez que acceda al Sitio, ya que pueden sufrir
        modificaciones. b. El Titular podrá modificar estos Términos y Condiciones en cualquier momento mediante la
        publicación en el Sitio de los términos modificados. Todos los términos modificados entrarán en vigor desde el
        momento de su publicación y se presumirán aceptados por el Usuario, en la medida en que siga utilizando el
        Sitio. c. El Titular se reserva el derecho de realizar, en cualquier momento y sin necesidad de preaviso,
        cualquier modificación o actualización de sus contenidos y servicios en general, así como de cuantos elementos
        integren el diseño y configuración del Sitio.
      </div>
      <li class="fw-bold">Acceso al Servicio</li>
      <div class="textos mb-3">
        Como parte de web3.0, su dirección de blockchain funciona como su identidad en CryptoCampo. Es por ello que
        necesitará una dirección de blockchain y una billetera (wallet) para acceder al Servicio. Su cuenta en el
        servicio ("Cuenta") se asociará con su dirección de blockchain.
      </div>
      <div class="textos mb-3">
        Su cuenta en CryptoCampo se asociará con su dirección de blockchain vinculada y mostrará los NFT para esa
        dirección de blockchain (y, si corresponde, cualquier contenido asociado con dichos NFT). Al usar su billetera
        (wallet) en relación con el Servicio, acepta que está usando esa billetera (wallet) según los términos y
        condiciones del proveedor correspondiente de la billetera. Las billeteras (wallets) no son operadas, mantenidas
        ni afiliadas a CryptoCampo, y CryptoCampo no tiene la custodia ni el control sobre el contenido de su billetera
        (wallet) y no tiene la capacidad de recuperar o transferir su contenido. CryptoCampo no acepta responsabilidad
        alguna por usted en relación con el uso que haga de una billetera (wallet) y no hace declaraciones ni garantías
        con respecto a cómo funcionará el Servicio con una billetera (wallet) específica. Usted es el único responsable
        de mantener su billetera (wallet) segura y nunca debe compartir las credenciales de su billetera (wallet) o la
        frase semilla con nadie. Si descubre un problema relacionado con su billetera (wallet), comuníquese con su
        proveedor de billetera (wallet). Del mismo modo, usted es el único responsable de su Cuenta y cualquier
        billetera (wallet) asociada y no somos responsables de ningún acto u omisión por su parte en relación con su
        Cuenta o como resultado de que su Cuenta o billetera (wallet) se vean comprometidas. Usted acepta notificarnos
        de inmediato si descubre o sospecha cualquier problema de seguridad relacionado con el Servicio o su Cuenta,
        siendo usted el único responsable de su Cuenta y billetera (wallet) asociada y no somos responsables de ningún
        acto u omisión por su parte en relación con su Cuenta o como resultado de que su Cuenta o billetera (wallet) se
        vean comprometidas. Usted acepta notificarnos de inmediato si descubre o sospecha cualquier problema de
        seguridad relacionado con el Servicio o su Cuenta (puede contactarnos a través de los canales informados en la
        sección <span class="fw-bold">AYUDA</span>
      </div>
      <div class="textos mb-3">
        También declara y garantiza que cumplirá con todas las leyes aplicables (por ejemplo, leyes locales,
        provinciales/estatales, federales y otras) cuando utilice el Servicio. Sin limitar lo anterior, al usar el
        Servicio, usted declara y garantiza que: (a) no se encuentra, reside habitualmente ni está organizado bajo las
        leyes de ninguna jurisdicción que esté sujeta a un embargo integral del Gobierno de los EE. UU. ("Jurisdicción
        Embargada "); (b) no está sujeto a ninguna sanción administrada por una agencia del gobierno de los EE. UU.,
        cualquier otro gobierno o las Naciones Unidas (colectivamente, "Sanciones"); (c) usted no es propiedad ni está
        controlado, directa o indirectamente, por ninguna persona que esté sujeta a Sanciones, o que esté ubicada,
        resida habitualmente u organizada bajo las leyes de cualquier Jurisdicción Embargada; (d) ninguno de sus
        funcionarios, gerentes, directores, accionistas o representantes autorizados está sujeto a Sanciones, o está
        ubicado, reside habitualmente en, o está organizado bajo las leyes de cualquier Jurisdicción Embargada, o es
        propiedad o está controlado, directa o indirectamente, por cualquier persona que está sujeta a Sanciones o que
        es ubicado en, residente habitual u organizado bajo las leyes de cualquier Jurisdicción Embargada. Además,
        acepta que lo anterior será cierto durante todo el período de este acuerdo. Si accede o utiliza el Servicio
        fuera de los Estados Unidos, usted es el único responsable de asegurarse de que su acceso y uso del Servicio en
        dicho país, territorio o jurisdicción no infrinja ninguna ley aplicable. o es propiedad o está bajo el control,
        directa o indirectamente, de cualquier persona que esté sujeta a Sanciones o que esté ubicada, resida
        habitualmente u organizada bajo las leyes de cualquier Jurisdicción Embargada.

      </div>
      <div class="textos mb-3">
        CryptoCampo puede solicitarle que proporcione información y documentos adicionales en determinadas
        circunstancias, por ejemplo, a solicitud de cualquier autoridad gubernamental, según lo dicte cualquier ley o
        regulación aplicable, o para investigar una posible violación de estos Términos. En tales casos, CryptoCampo, a
        su exclusivo criterio, puede desactivar su Cuenta y bloquear su capacidad para acceder al Servicio hasta que
        CryptoCampo procese dicha información y documentos adicionales. Si no proporciona información completa y precisa
        en respuesta a dicha solicitud, CryptoCampo puede negarse a restaurar su acceso al Servicio.
      </div>
      <div class="textos mb-3">
        Su acceso y uso del Servicio puede verse interrumpido de vez en cuando por varios motivos, incluidos, entre
        otros, el mal funcionamiento del equipo, la actualización periódica, el mantenimiento o la reparación del
        Servicio u otras acciones que CryptoCampo, a su exclusivo criterio , puede elegir tomar. Si su Cuenta queda
        inactiva durante dieciocho meses o más, ello por no haber hecho el reclamo (claim) de la campaña en la cual
        participó al adquirir un NFT, según lo determine CryptoCampo a su exclusivo criterio, usted comprende que
        CryptoCampo puede desactivar su Cuenta o reasignar su nombre de usuario o URL asociada. Al hablar de
        inactividad, significa.
      </div>
      <div class="textos mb-3">
        Requerimos que todos los usuarios tengan al menos 18 años. Si tiene entre 13 a 17 años, solo puede usar
        CryptoCampo a través de la Cuenta de un padre, madre o tutor y con su aprobación y supervisión. Ese titular de
        la cuenta es responsable de sus acciones al usar la Cuenta. Está prohibido utilizar nuestro Servicio si es menor
        de 13 años.
      </div>
      <li class="fw-bold">Propiedad</li>
      <div class="textos mb-3">
        El Servicio, incluido su "aspecto y funcionamiento" (p. ej., texto, gráficos, imágenes, logotipos, encabezados
        de página, íconos de botones y guiones), contenido registrado, información y otros materiales, y todo el
        contenido y otros materiales contenidos en él, incluidos, sin limitación, el logotipo de CryptoCampo y todos los
        diseños, textos, gráficos, imágenes, datos, software, archivos de sonido, otros archivos y la selección y
        disposición de los mismos son propiedad exclusiva de CryptoCampo o de nuestros afiliados, otorgantes de
        licencias o usuarios, según corresponda. y usted acepta no tomar ninguna medida que sea incompatible con dichos
        intereses de propiedad. Nosotros y nuestros afiliados, licenciantes y usuarios, según corresponda, nos
        reservamos todos los derechos en relación con el Servicio y su contenido, incluido, entre otros, el derecho
        exclusivo de crear trabajos derivados.
      </div>
      <div class="textos mb-3">
        El nombre, el logotipo, las marcas comerciales y los nombres, diseños, logotipos y eslóganes de cualquier
        producto o servicio de CryptoCampo son propiedad intelectual de CryptoCampo o de nuestras filiales o otorgantes
        de licencias y no pueden copiarse, imitarse ni utilizarse, en su totalidad o en parte, sin nuestra autorización
        previa por escrito en cada caso. No puede usar ninguna metaetiqueta u otro "texto oculto" que utilice
        "CryptoCampo" o cualquier otro nombre, marca comercial o nombre de producto o servicio de CryptoCampo o de
        nuestros afiliados o licenciantes sin nuestro permiso previo por escrito. Además, la "apariencia" del Servicio
        constituye la marca de servicio, la marca comercial o la imagen comercial de CryptoCampo y no puede copiarse,
        imitarse ni utilizarse, en su totalidad o en parte, sin nuestro permiso previo por escrito.
      </div>
      <div class="textos mb-3">
        Todas las demás marcas comerciales, marcas comerciales registradas y nombres de productos de terceros
        mencionados en el Servicio o incluidos en el contenido vinculado o asociado con cualquier NFT que se muestre en
        el Servicio son propiedad de sus respectivos propietarios y no se pueden copiar, imitar ni utilizar en su
        totalidad o en parte, sin el permiso del titular de los derechos de propiedad intelectual aplicables. La
        referencia a cualquier producto, servicio, proceso u otra información por nombre, marca comercial, fabricante,
        proveedor o de otro modo no constituye ni implica respaldo, patrocinio o recomendación por parte de CryptoCampo.
      </div>
      <li class="fw-bold">Contenido y servicios</li>
      <div class="textos mb-3">
        Como un servicio web3.0, CryptoCampo lo ayuda a adquirir NFT creados por CryptoCampo para participar de campañas
        agropecuarias, la o las cuales se ofrecen en el sitio, con el informe, ubicación y plazo de duración
        correspondiente (ver “Información de la campaña”). Por ejemplo, cuando hace clic para obtener más detalles sobre
        cualquiera de los NFT visibles en CryptoCampo, puede notar un enlace a la información detallada de la campaña.
        Usted es el único responsable de revisar dichos Términos de compra, como así también la información sobre la
        campaña agropecuaria a la cual está accediendo con la compra del NFT en la plataforma.
      </div>
      <div class="textos mb-3">
        El Servicio también puede contener enlaces o funciones para acceder o utilizar sitios web de terceros ("Sitios
        web de terceros") y aplicaciones ("Aplicaciones de terceros"), o mostrar, incluir o poner a disposición
        contenido, datos, información, servicios, aplicaciones o materiales de terceros ("Materiales de terceros").
        Cuando hace clic en un enlace o accede y utiliza un sitio web de terceros o una aplicación de terceros, aunque
        es posible que no le advirtamos que ha abandonado nuestro Servicio, está sujeto a los términos y condiciones
        (incluidas las políticas de privacidad). de otro sitio web o destino. Dichos sitios web de terceros,
        aplicaciones de terceros y materiales de terceros no están bajo el control de CryptoCampo y pueden ser
        aplicaciones "abiertas" para las que no es posible recurrir. CryptoCampo no es responsable de ningún sitio web
        de terceros, Aplicaciones de terceros y materiales de terceros. CryptoCampo proporciona enlaces a estos sitios
        web de terceros y aplicaciones de terceros sólo para su comodidad y no revisa, aprueba, supervisa, respalda,
        garantiza ni hace ninguna representación con respecto a los sitios web de terceros o las aplicaciones de
        terceros, o sus productos o servicios o Materiales de Terceros asociados. Usted utiliza todos los enlaces en
        sitios web de terceros, aplicaciones de terceros y materiales de terceros bajo su propio riesgo.
      </div>
      <li class="fw-bold">Conducta del usuario</li>
      <div class="textos mb-3">
        La apertura a todas las regiones y países del mundo es uno de nuestros valores más preciados, y estamos
        comprometidos a proporcionar a personas de todos los ámbitos de la vida la experiencia con tecnología web3.0.
        Sin embargo, para proteger a nuestra comunidad y cumplir con nuestras obligaciones legales, nos reservamos el
        derecho de tomar medidas, con o sin previo aviso, si creemos que ha violado estos Términos. Esto puede incluir:
        eliminar la capacidad de ver ciertos NFT en el Servicio o usar nuestro Servicio para interactuar con los NFT;
        deshabilitar la capacidad de usar el Servicio junto con la compra/venta/transferencia de NFT disponibles en
        blockchains; deshabilitar su capacidad para acceder a nuestro Servicio; y/u otras acciones.
      </div>
      <div class="textos mb-3">
        Usted acepta que no violará ninguna ley, contrato, propiedad intelectual u otro derecho de terceros y/o de la
        plataforma CryptoCampo, y que es el único responsable de su conducta y contenido, mientras accede o usa el
        Servicio. También acepta que no:
        <ul>
          <li class="mb-3">Usará o intentará usar la Cuenta de otro usuario sin la autorización de dicho usuario;</li>
          <li class="mb-3">Hacerse pasar por otra persona o entidad, o usar una billetera (wallet) para realizar una
            transacción en
            CryptoCampo que sea propiedad o esté controlada, total o parcialmente, por otra persona;</li>
          <li class="mb-3">Reclamar un nombre de usuario de CryptoCampo con el fin de revenderlo, confundir a otros,
            derivar la buena
            voluntad de otros o participar en la usurpación de nombres; </li>
          <li class="mb-3">Acceder al Servicio desde una dirección blockchain diferente si hemos bloqueado el acceso al
            Servicio a cualquiera de sus otras direcciones de cadena de bloques, a menos que primero tenga nuestro
            permiso por escrito vía email u otro canal oficial de comunicación;</li>
          <li class="mb-3">Distribuir spam, incluso mediante el envío de NFT no deseados a otros usuarios;</li>
          <li class="mb-3">Usar el Servicio, incluso mediante la difusión de cualquier software o la interacción con
            cualquier API, que podría dañar, deshabilitar, sobrecargar o perjudicar el funcionamiento del Servicio de
            cualquier manera;</li>
          <li class="mb-3">Omitir o ignorar las instrucciones que controlan el acceso al Servicio, incluido el intento
            de eludir cualquier sistema de limitación de velocidad mediante el uso de múltiples claves API, dirigiendo
            el tráfico a través de múltiples direcciones IP u ofuscando de otra manera la fuente del tráfico que envía a
            CryptoCampo;</li>
          <li class="mb-3">Usar nuestro Servicio para fines comerciales que no concuerden con estos Términos o cualquier
            otra instrucción;</li>
          <li class="mb-3">Usar cualquier minería de datos, robot, spider, rastreador, raspador, secuencia de comandos,
            extensión de navegador, lector fuera de línea u otros medios o interfaces automatizados no autorizados por
            nosotros para acceder al Servicio, extraer datos o de otra manera interferir con o modificar la
            representación de las páginas del Servicio. o funcionalidad;</li>
          <li class="mb-3">Realizar ingeniería inversa, duplicar, descompilar, desensamblar o decodificar cualquier
            aspecto del Servicio, o hacer cualquier cosa que pueda descubrir el código fuente o eludir o eludir las
            medidas empleadas para evitar o limitar el acceso a cualquier servicio, área o código del Servicio;</li>
          <li class="mb-3">Vender o revender el Servicio o intentar eludir cualquier sistema de tarifas de CryptoCampo;
          </li>
          <li class="mb-3">Participar en comportamientos que tengan la intención o el efecto de hacer que un elemento o
            colección aparezca artificialmente en la parte superior de los resultados de búsqueda, o aumentar
            artificialmente el número de vistas, los favoritos u otras métricas que CryptoCampo podría usar para ordenar
            los resultados de búsqueda;</li>
          <li class="mb-3">Usar el Servicio o los datos recopilados de nuestro Servicio para cualquier actividad
            publicitaria o de marketing directo (incluidos, entre otros, marketing por correo electrónico, marketing por
            SMS y telemarketing);</li>
          <li class="mb-3">Utilizar el Servicio para o en relación con el blanqueo de capitales, la financiación del
            terrorismo u otra actividad financiera ilícita, o de cualquier forma en relación con la infracción de
            cualquier ley o reglamento que se aplique a usted o a CryptoCampo;</li>
          <li class="mb-3">Usar el Servicio, directa o indirectamente, para, en nombre de o en beneficio de (a)
            cualquier persona física o jurídica que sea objeto de Sanciones; (b) cualquier persona física o jurídica
            ubicada en, residente habitual u organizada bajo las leyes de cualquier Jurisdicción Embargada; o (c)
            cualquier persona jurídica que sea propiedad o esté controlada, directa o indirectamente, por cualquier
            persona física o jurídica ubicada, residente habitual u organizada conforme a las leyes de cualquier
            Jurisdicción Embargada.</li>
          <li class="mb-3">Utilizar el Servicio para llevar a cabo cualquier actividad financiera sujeta a registro o
            licencia, incluidas, entre otras, la creación, oferta, venta o compra de valores, materias primas, opciones
            o instrumentos de deuda;</li>
          <li class="mb-3">Usar el Servicio para crear, vender o comprar NFT u otros artículos que otorguen a los
            propietarios derechos para participar en una ICO o cualquier oferta de valores, o que sean canjeables por
            valores, materias primas u otros instrumentos financieros;</li>
          <li class="mb-3">Usar el Servicio para participar en manipulación de precios, fraude u otra actividad
            engañosa, engañosa o manipuladora;</li>
          <li class="mb-3">Usar el Servicio para comprar, vender o transferir artículos robados, artículos obtenidos de
            manera fraudulenta, artículos tomados sin autorización y/o cualquier otro artículo obtenido ilegalmente;
          </li>
          <li class="mb-3">Infringir o violar los derechos de propiedad intelectual o cualquier otro derecho de
            terceros;</li>
          <li class="mb-3">Crear o mostrar contenido ilegal, como contenido que pueda implicar explotación sexual
            infantil;</li>
          <li class="mb-3">Usar el Servicio para cualquier propósito ilegal o no autorizado</li>
          <li class="mb-3">Use el Servicio de cualquier manera que pueda interferir, interrumpir, afectar negativamente
            o impedir que otros usuarios disfruten plenamente del Servicio.</li>
        </ul>
        <div class="textos mb-3">
          Finalmente, al usar el Servicio, comprende la importancia de DYOR - do your own research-, que significa HACER
          SU PROPIA INVESTIGACIÓN. Usted tiene toda la responsabilidad de verificar la autenticidad, legitimidad,
          identidad y otros detalles sobre cualquier NFT, campaña agropecuaria ofrecida, usuario o cuenta que vea o con
          la que interactúe junto con nuestro Servicio.
        </div>
      </div>
      <li class="fw-bold">Alcance de los servicios</li>
      <div class="textos mb-3">
        El Usuario acepta y reconoce que la aceptación de estos Términos y Condiciones no crea ningún contrato de
        sociedad, de mandato, de franquicia, o relación laboral entre el Sitio y el Usuario.
      </div>
      <div class="textos mb-3">
        Queda expresamente establecido, y el usuario acepta que la Sociedad, a través del Sitio, no proporciona ningún
        tipo de consejo de inversión en relación con los servicios prestados. La Sociedad podrá proporcionar información
        sobre el precio, el rango, la volatilidad de los bienes y/o de la producción agrícola o de cualquier tipo y
        eventos que han afectado el precio de los bienes y/o de la producción agrícola o de cualquier tipo, pero esto en
        ningún caso se considerará asesoría financiera o de inversión y no deberá interpretarse como tal. Cualquier
        decisión de compra o venta del NFT es decisión exclusiva del Usuario, y la Sociedad no será responsable de
        cualquier pérdida sufrida como consecuencia de esa decisión.
      </div>
      <li class="fw-bold">Empleados del Sitio.</li>
      <div class="textos mb-3">
        Los empleados en relación de dependencia con la sociedad y/o personas físicas y jurídicas que presten sus
        servicios independientes para Cryptocampo, podrán utilizar el Sitio siguiendo estrictamente estos Términos y
        Condiciones. Se entenderá que hacen uso del Sitio con propósitos personales y bajo ningún concepto en nombre del
        Titular ni ninguna de sus sociedades vinculadas o controladas. Por este motivo, ni la Sociedad ni ninguna de sus
        sociedades vinculadas o controladas se responsabilizan por los contactos que éstos realicen. Los empleados del
        Sitio están sujetos a estos TyC en las mismas condiciones que cualquier Usuario del Sitio. Para utilizar El
        Sitio, sus empleados deben consignar su dirección de e-mail y domicilio personales y no las de su empleo en la
        Sociedad. </div>
      <li class="fw-bold">Preferencia de comunicacion</li>
      <div class="textos mb-3">
        Al crear una Cuenta, acepta recibir comunicaciones electrónicas de CryptoCampo (p. ej., por correo electrónico,
        notificaciones automáticas, mensajes de texto u otros tipos de mensajes). Estas comunicaciones pueden incluir
        avisos sobre su Cuenta (p. ej., información transaccional, posibilidad de reclamar o claimear su NFT porque ha
        finalizado la campaña agropecuaria en la plataforma) y son parte de su relación con nosotros. También podemos
        enviarle comunicaciones promocionales por correo electrónico que creemos que pueden ser de su interés. Usted
        comprende que no está obligado a proporcionar este consentimiento como condición para usar el Servicio y puede
        optar por no recibir estas comunicaciones a través del Servicio o a través del sistema operativo de su
        dispositivo móvil (con la posible excepción de anuncios de servicio importantes y mensajes administrativos) al
        siguiendo las instrucciones de cancelación de suscripción proporcionadas.</div>
      <li class="fw-bold">Jurisdicción</li>
      <div class="textos mb-3">
        El Titular del Sitio, las sociedades vinculadas, sus directivos, empleados y representantes no aseguran que los
        contenidos puedan ser visualizados, utilizados y/o descargados legítimamente dentro y/o fuera de su país de
        residencia. El acceso a los contenidos por ciertas personas o en ciertos países puede ser ilegal. El Usuario
        accede al Sitio bajo su propia responsabilidad y es responsable de cumplir las leyes de su jurisdicción que
        resulten aplicables. Si cualquier cláusula de estos Términos y Condiciones se declarase nula por cualquier
        órgano jurisdiccional competente, la nulidad de tal cláusula no afectará a la validez de las restantes cláusulas
        de estos TyC, que mantendrán su plena vigencia y efecto. Ninguna renuncia a cualquier cláusula contenida en
        estos TyC será considerada como una renuncia continuada de dicha cláusula o cualquier otra cláusula.</div>
      <li class="fw-bold">Indemnización</li>
      <div class="textos mb-3">
        Al aceptar estos Términos y condiciones para acceder al Servicio, usted acepta, en la máxima medida permitida
        por la ley aplicable, indemnizar, defender y eximir de responsabilidad a CryptoCampo y a nuestros respectivos
        empleados, funcionarios, founders, directores, contratistas y consultores pasados, presentes y futuros. ,
        accionistas, proveedores, vendedores, proveedores de servicios, empresas matrices, subsidiarias, afiliadas,
        agentes, representantes, predecesores, sucesores y cesionarios (individual y colectivamente, las "Partes de
        CryptoCampo"), de y contra todos los reclamos, daños reales o presuntos , laudos, sentencias, pérdidas,
        responsabilidades, obligaciones, impuestos, multas, intereses, honorarios, gastos (incluidos, entre otros,
        honorarios y gastos de abogados) y costos (incluidos, entre otros, costos judiciales, costos de liquidación y
        costos de indemnización y seguro), de toda especie y naturaleza, sea conocida o desconocida, prevista o
        imprevista, vencida o no vencida, sospechosa o no sospechosa, de derecho o de equidad, ya sea en daños y
        perjuicios, contrato, o de otro modo (colectivamente, "Reclamos"), incluidos, entre otros, daños a la propiedad
        o lesiones personales, que son causados, surgen de o están relacionados con (a) su uso o mal uso del Servicio,
        contenido, NFT o contenido vinculado o asociado con cualquier NFT (b) cualquier Comentarios que proporciona, (c)
        su violación o incumplimiento de cualquier término de estos Términos o la ley aplicable, y (d) su violación de
        los derechos u obligaciones de un tercero, incluido otro usuario o tercero, y (e) su negligencia o dolo. Usted
        acepta notificar de inmediato a CryptoCampo sobre cualquier Reclamo y cooperar con las Partes de CryptoCampo en
        la defensa de dichos Reclamos. Además, acepta que las partes de cryptocampo tendrán el control de la defensa o
        resolución de cualquier reclamo judicial o extrajudicial. Esta indemnización o compensación es en adición, y no
        en lugar de, cualquier otra indemnización establecida en un acuerdo escrito entre usted y CryptoCampo.</div>
      <li class="fw-bold">Descargos de responsabilidad</li>
      <div class="textos mb-3">
        SU ACCESO Y USO DEL SERVICIO ES BAJO SU PROPIO RIESGO. USTED ENTIENDE Y ACEPTA QUE EL SERVICIO SE PROPORCIONA
        "TAL CUAL" Y "SEGÚN DISPONIBILIDAD" Y CRYPTOCAMPO EXPRESAMENTE RENUNCIA A GARANTÍAS O CONDICIONES DE CUALQUIER
        TIPO, YA SEA EXPLÍCITA O IMPLÍCITA. CRYPTOCAMPO (Y SUS PROVEEDORES) NO OFRECEN NINGUNA GARANTÍA O REPRESENTACIÓN
        Y NIEGA TODA RESPONSABILIDAD POR SI EL SERVICIO: (A) CUMPLIRÁ CON SUS REQUISITOS; (B) ESTARÁ DISPONIBLE DE FORMA
        ININTERRUMPIDA, OPORTUNA, SEGURA O LIBRE DE ERRORES; O (C) SERÁ PRECISO, CONFIABLE, COMPLETO, LEGAL O SEGURO.
        CRYPTOCAMPO RENUNCIA A TODAS LAS DEMÁS GARANTÍAS O CONDICIONES, EXPRESAS O IMPLÍCITAS, INCLUYENDO, SIN
        LIMITACIÓN, GARANTÍAS O CONDICIONES IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO, TÍTULO Y
        NO VIOLACIÓN. CRYPTOCAMPO NO SERÁ RESPONSABLE DE NINGUNA PÉRDIDA DE NINGÚN TIPO DERIVADA DE CUALQUIER ACCIÓN
        REALIZADA O REALIZADA EN FUNCIÓN DEL MATERIAL O LA INFORMACIÓN CONTENIDOS EN EL SERVICIO. MIENTRAS CRYPTOCAMPO
        TRABAJA PARA DARLE AL USUARIO UN ACCESO Y USO DEL SERVICIO SEGURO, CRYPTOCAMPO NO PUEDE Y NO REPRESENTA NI
        GARANTIZA QUE CUALQUIER USUARIO CON EL QUE USTED INTERACTÚA UTILIZANDO NUESTRO SERVICIO NO LE REALICE NINGUN
        DAÑO, LO MISMO SI INGRESA EN UNA PLATAFORMA QUE NO PERTENECE A CRYPTOCAMPO. NO PODEMOS GARANTIZAR LA SEGURIDAD
        DE CUALQUIER DATO QUE USTED DIVULGUE EN LÍNEA O A ALGÚN USUARIO O POR LAS REDES SOCIALES. NINGÚN CONSEJO O
        INFORMACIÓN, YA SEA ORAL U OBTENIDA DE LAS PARTES DE CRYPTOCAMPO O A TRAVÉS DEL SERVICIO, CREARÁ NINGUNA
        GARANTÍA O REPRESENTACIÓN QUE NO SE HAYA EXPRESAMENTE HECHO EN EL PRESENTE DOCUMENTO.</div>
      <div class="textos mb-3">
        NO SEREMOS RESPONSABLES ANTE USTED POR NINGUNA PÉRDIDA Y NO ASUMIMOS RESPONSABILIDAD POR, Y NO SEREMOS
        RESPONSABLES ANTE USTED POR, CUALQUIER USO DE NFTS, CONTENIDO Y/O CONTENIDO VINCULADO O ASOCIADO CON NFTS,
        INCLUYENDO PERO NO LIMITADO A CUALQUIER PÉRDIDA, DAÑO O RECLAMACIÓN QUE SURJA DE: (A) ERROR DEL USUARIO,
        TRANSACCIONES FALLIDAS, CONSTRUIDAS INCORRECTAMENTE O DIRECCIONES MAL ESCRITAS; (B) FALLA DEL SERVIDOR O PÉRDIDA
        DE DATOS; (C) ACCESO O USO NO AUTORIZADO; (D) CUALQUIER ACTIVIDAD NO AUTORIZADA DE TERCEROS, INCLUYENDO SIN
        LIMITACIÓN EL USO DE VIRUS, PHISHING, FUERZA BRUTA U OTROS MEDIOS DE ATAQUE CONTRA EL SERVICIO O NFTS.</div>
      <div class="textos mb-3">
        LOS NFTS EXISTEN ÚNICAMENTE EN VIRTUD DEL REGISTRO DE PROPIEDAD MANTENIDO EN LA BLOCKCHAIN ASOCIADA (P. Ej., RED
        BINANCE, BNB Chain). TODAS LAS TRANSFERENCIAS O VENTAS OCURREN EN LA CADENA DE BLOQUES ASOCIADA (P. Ej., RED
        BINANCE, BNB Chain). CRYPTOCAMPO Y/O CUALQUIER OTRA PARTE DE CRYPTOCAMPO NO PUEDEN EFECTUAR O CONTROLAR DE OTRO
        MODO LA TRANSFERENCIA DE TÍTULO O DERECHO EN CUALQUIER NFTS O CONTENIDO O ARTÍCULOS SUBYACENTES O ASOCIADOS.
      </div>
      <div class="textos mb-3">
        NINGUNA PARTE DE CRYPTOCAMPO ES RESPONSABLE DE CUALQUIER PÉRDIDA O LESIÓN SOSTENIDA DEBIDA A VULNERABILIDAD O
        CUALQUIER TIPO DE FALLA, COMPORTAMIENTO ANORMAL DEL SOFTWARE (P. Ej., WALLET, SMART CONTRACT), BLOCKCHAINS O
        CUALQUIER OTRA CARACTERÍSTICA DEL NFTS. NINGUNA PARTE DE CRYPTOCAMPO ES RESPONSABLE POR PÉRDIDAS O LESIONES
        DEBIDO A INFORMES TARDÍOS POR PARTE DE DESARROLLADORES O REPRESENTANTES (O NINGÚN INFORME) DE CUALQUIER PROBLEMA
        CON LA BLOCKCHAIN QUE APOYA EL NFTS, INCLUYENDO FORKS, PROBLEMAS DE NODO TÉCNICO O CUALQUIER OTRO PROBLEMA QUE
        TENGA PÉRDIDAS O LESIONES COMO RESULTADO.</div>
      <div class="textos mb-3">
        Algunas jurisdicciones no permiten la exclusión de garantías implícitas en contratos con consumidores, por lo
        que es posible que la exclusión anterior no se aplique a usted.</div>
      <li class="fw-bold">Asunción de Riesgo</li>
      <div class="textos mb-3">
        Usted acepta y reconoce:
      </div>
      <ul>
        <li>El valor de un NFT está inicialmente determinado por la plataforma de Cryptocampo. Los precios de los NFT
          están sujetos a la volatilidad y las fluctuaciones en el precio de las criptomonedas, inclusive de las
          llamadas “stablecoins”, también pueden afectar material y adversamente los precios de los NFT. Usted reconoce
          que comprende completamente esta subjetividad y volatilidad y que puede perder dinero.</li>
        <li>La falta de uso o interés público en la creación y desarrollo de ecosistemas distribuidos podría afectar
          negativamente el desarrollo de esos ecosistemas y aplicaciones relacionadas y, por lo tanto, también podría
          afectar negativamente la utilidad potencial de los NFT.</li>
        <li>El régimen regulatorio que rige las tecnologías blockchain, los tokens no fungibles, las criptomonedas y
          otros elementos basados en criptomonedas es incierto, y las nuevas regulaciones o políticas pueden afectar
          negativamente el desarrollo del Servicio y la utilidad de las NFT.</li>
        <li>El valor de un NFT está inicialmente determinado por la plataforma de Cryptocampo. Los precios de los NFT
          están sujetos al resultado de la campaña agropecuaria a la cual usted decidió participar al adquirir el NFT en
          la plataforma, las fluctuaciones en la rentabilidad obtenida al finalizar la campaña agropecuaria o durante la
          misma, también pueden afectar los precios de los NFT y su rentabilidad final. Usted reconoce que comprende
          completamente esta subjetividad y volatilidad y que puede perder dinero.</li>
        <li>CryptoCampo no promete una rentabilidad ni una ganancia de dinero, la compra del NFT le asegura la
          participación como inversor en la campaña agropecuaria propuesta en la plataforma. El NFT representa una
          inversión y no un préstamo a la plataforma de CryptoCampo, lo cual presenta riesgos y no asegura ganancia.
        </li>
        <li>Usted es el único responsable de determinar qué impuestos, si corresponde, se aplican a sus transacciones y
          de retener, recaudar, declarar y remitir los montos correctos de impuestos a las autoridades fiscales
          correspondientes. CryptoCampo no es responsable de determinar, retener, recaudar, informar o remitir los
          impuestos que se aplican a sus NFT.</li>
        <li>Existen riesgos asociados con la compra de NFT ofrecidos por terceros a través de transacciones entre pares,
          incluido, entre otros, el riesgo de comprar NFTs falsificados, artículos mal etiquetados, artículos que son
          vulnerables a la degradación de metadatos, artículos en contratos inteligentes con errores y elementos que
          pueden volverse intransferibles. Usted declara y garantiza que ha investigado lo suficiente antes de tomar
          cualquier decisión de vender, obtener, transferir o interactuar de otro modo con NFT o cuentas/colecciones.
        </li>
        <li>No controlamos las blockchains públicas con las que está interactuando y no controlamos ciertos contratos y
          protocolos inteligentes que pueden ser parte integral de su capacidad para completar transacciones en estas
          blockchains públicas. Además, las transacciones de la blockchain son irreversibles y CryptoCampo no tiene la
          capacidad de revertir ninguna transacción en la blockchain.</li>
        <li>Existen riesgos asociados con el uso de Internet y productos basados en blockchain, incluidos, entre otros,
          el riesgo asociado con el hardware, el software y las conexiones a Internet, el riesgo de introducción de
          software malicioso y el riesgo de que terceros puedan obtener acceso no autorizado a su billetera (wallet) o
          cuenta de terceros. Usted acepta y reconoce que CryptoCampo no será responsable de las fallas de comunicación,
          las interrupciones, los errores, las distorsiones o los retrasos que pueda experimentar al usar el Servicio o
          cualquier red Blockchain, independientemente de la causa.</li>
        <li>El Servicio se basa en plataformas y/o proveedores de terceros. Si no podemos mantener una buena relación
          con dichos proveedores de plataformas y/o vendedores; si los términos y condiciones o los precios de dichos
          proveedores de plataformas y/o vendedores cambian; si violamos o no podemos cumplir con los términos y
          condiciones de dichas plataformas y/o proveedores; o si cualquiera de dichas plataformas y/o proveedores
          pierde cuota de mercado o cae en desgracia o no está disponible durante un período prolongado de tiempo, el
          acceso y el uso del Servicio se verán afectados.</li>
        <li>CryptoCampo se reserva el derecho de ocultar cobros, contratos y artículos afectados por cualquiera de estos
          problemas o por otros problemas. Los artículos que compre pueden volverse inaccesibles en CryptoCampo. Bajo
          ninguna circunstancia la imposibilidad de ver artículos en CryptoCampo o la incapacidad de usar el Servicio
          junto con la compra, venta o transferencia de artículos disponibles en cualquier cadena de bloques servirá
          como motivo para un reclamo contra CryptoCampo.</li>
        <li>Si tiene una disputa con uno o más usuarios, USTED NOS LIBERA DE RECLAMOS, DEMANDAS Y DAÑOS DE TODO TIPO Y
          NATURALEZA, CONOCIDOS Y DESCONOCIDOS, QUE SURJAN DE DICHAS DISPUTAS O ESTÉN RELACIONADOS CON ELLAS. AL ENTRAR
          EN ESTA RENUNCIA, USTED RENUNCIA EXPRESAMENTE A CUALQUIER PROTECCIÓN (YA SEA LEGAL O DE OTRO TIPO) QUE DE OTRO
          MODO LIMITARÍA LA COBERTURA DE ESTA RENUNCIA PARA INCLUIR AQUELLOS RECLAMOS QUE PUEDE SABER O SOSPECHAR QUE
          EXISTEN A SU FAVOR AL MOMENTO DE ACEPTAR ESTA RENUNCIA.</li>
      </ul>
      <li class="fw-bold">Limitación de responsabilidad</li>
      <div class="textos mb-3">
        EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY, USTED ACEPTA QUE EN NINGÚN CASO CRYPTOCAMPO O SUS PROVEEDORES DE
        SERVICIOS SERÁN RESPONSABLES ANTE USTED O CUALQUIER TERCERO POR CUALQUIER PÉRDIDA DE BENEFICIOS O DAÑOS
        INDIRECTOS, EMERGENTES, EJEMPLARES, INCIDENTALES, ESPECIALES O PUNITIVOS QUE SURJAN DE ESTOS TÉRMINOS O EL
        SERVICIO, PRODUCTOS O SITIOS Y PRODUCTOS DE TERCEROS, O POR CUALQUIER DAÑO RELACIONADO CON PÉRDIDA DE INGRESOS,
        PÉRDIDA DE BENEFICIOS, PÉRDIDA DE NEGOCIOS O AHORROS ESPERADOS, PÉRDIDA DE USO, PÉRDIDA DE FONDO DE COMERCIO O
        PÉRDIDA DE DATOS, Y SI CAUSADOS POR RESPONSABILIDAD ESTRICTA O AGRAVIO (INCLUYENDO NEGLIGENCIA), INCUMPLIMIENTO
        DE CONTRATO O DE CUALQUIER OTRO CASO, INCLUSO SI CRYPTOCAMPO O SUS PROVEEDORES DE SERVICIOS HAN SIDO ADVERTIDO
        DE LA POSIBILIDAD DE DICHOS DAÑOS; O (B) POR CUALQUIER OTRO RECLAMO, DEMANDA O DAÑOS QUE RESULTEN O SURJAN DE O
        EN RELACIÓN CON ESTOS TÉRMINOS DE ENTREGA, USO, O EJECUCIÓN DEL SERVICIO. EL ACCESO Y EL USO DEL SERVICIO, LOS
        PRODUCTOS O LOS SITIOS DE TERCEROS Y LOS PRODUCTOS SON BAJO SU PROPIA DISCRECIÓN Y RIESGO, Y USTED SERÁ EL ÚNICO
        RESPONSABLE DE CUALQUIER DAÑO A SU SISTEMA INFORMÁTICO O DISPOSITIVO MÓVIL O LA PÉRDIDA DE DATOS RESULTANTE DE
        LOS MISMOS.</div>
      <div class="textos mb-3">
        SIN PERJUICIO DE CUALQUIER DISPOSICIÓN EN CONTRARIO CONTENIDA EN ESTE DOCUMENTO, EN NINGÚN CASO LA
        RESPONSABILIDAD TOTAL MÁXIMA DE CRYPTOCAMPO DERIVADA DE O DE ALGUNA FORMA RELACIONADA CON ESTOS TÉRMINOS, EL
        ACCESO Y EL USO DEL SERVICIO, CONTENIDO, NFTS O CUALQUIER PRODUCTO O SERVICIO DE CRYPTOCAMPO EXCEDERÁ EL MAYOR
        DE (A) BUSD 100 (BUSD CIEN) O EL EQUIVALENTE EN UNA STABLE COIN (MONEDA ESTABLE) A USD100 (CIEN DOLARES
        ESTADOUNIDENSES) O (B) EL MONTO RECIBIDO POR CRYPTOCAMPO POR SU SERVICIO RELACIONADO DIRECTAMENTE CON LOS
        ARTÍCULOS QUE SON OBJETO DEL RECLAMO. LAS LIMITACIONES ANTERIORES SE APLICARÁN INCLUSO SI EL RECURSO ESTABLECIDO
        ANTERIORMENTE FALLA EN SU PROPÓSITO ESENCIAL.</div>
      <div class="textos mb-3">
        Algunas jurisdicciones no permiten la exclusión o limitación de daños incidentales o consecuentes, por lo que es
        posible que la limitación o exclusión anterior no se aplique a usted. Algunas jurisdicciones también limitan las
        exenciones de responsabilidad o las limitaciones de responsabilidad por lesiones personales de los productos de
        consumo, por lo que esta limitación puede no aplicarse a las reclamaciones por lesiones personales.</div>
      <li class="fw-bold">Modificaciones al Servicio</li>
      <div class="textos mb-3">
        Nos reservamos el derecho, a nuestro exclusivo criterio, de modificar, suspender o interrumpir, temporal o
        permanentemente, el Servicio (o cualquier característica o parte del mismo) en cualquier momento y sin
        responsabilidad por ello.</div>
      <li class="fw-bold">Resolución de conflictos: Arbitraje</li>
      <ul>
        <li><span class="fw-bold">Resolución de conflictos: </span> Lea atentamente el siguiente acuerdo de arbitraje en
          esta Sección ("Acuerdo de arbitraje"). Requiere que usted arbitre las disputas con CryptoCampo y limita la
          forma en que puede solicitar nuestra reparación. Esta sección no rige las disputas entre usuarios o entre
          usuarios y terceros. CryptoCampo no proporciona servicios de resolución de disputas para tales desacuerdos y
          las partes deben resolver esas disputas directamente.</li>
        <li><span class="fw-bold">Aplicabilidad del Acuerdo de Arbitraje: </span>Usted acepta que cualquier disputa,
          controversia o reclamo relacionado de alguna manera con su acceso o uso del Servicio, con cualquier producto
          vendido o distribuido a través del Servicio, o con cualquier aspecto de su relación con CryptoCampo, se
          resolverá mediante el sistema de arbitraje vinculant proporcionado por la plataforma Kleros
          (https://kleros.io), la cual es una aplicación descentralizada construida sobre Ethereum, que funciona como
          una tercera parte descentralizada para el arbitraje de disputas en cualquier tipo de contratos, ya sean
          simples o muy complejos. El resultado es un sistema de resolución de disputas que alcanza resultados de manera
          rápida, económica, confiable y descentralizada </li>
        <li><span class="fw-bold">Proceso de resolución de disputas: </span>Usted y CryptoCampo acuerdan participar en
          esfuerzos de buena fe para resolver disputas antes de que cualquiera de las partes inicie un arbitraje o un
          procedimiento judicial. Debe iniciar este proceso de resolución de disputas enviando un email que describa la
          naturaleza de su reclamo y la resolución deseada a: CryptoCampo, info@cryptocampo.net y también a
          desarrollo@cryptocampo.net . Ambas partes acuerdan reunirse y consultar personalmente, por teléfono o por
          videoconferencia (en adelante, “Conferencia”) para discutir la disputa e intentar de buena fe llegar a un
          resultado de beneficio mutuo que evite los gastos de arbitraje o, en su caso, litigio. Si está representado
          por un abogado, su abogado también puede participar en la Conferencia. Asimismo, si CryptoCampo está
          representada por un abogado, su abogado también puede participar en la Conferencia, pero CryptoCampo acepta
          que un representante de la empresa participe plenamente en la Conferencia. </li>
        <li><span class="fw-bold">Reglamento y Foro de Arbitraje: </span>Para comenzar un procedimiento de arbitraje
          después de participar en el proceso de resolución de disputas, debe comunicarse informando su reclamo a
          nuestra oficina de legales de CryptoCampo, enviando un email a soporte@cryptocampo.net, con copia a
          info@cryptocampo.net y también a desarrollo@cryptocampo.net. El arbitraje lo llevará a cabo KLEROS, conforme
          lo indicado precedentemente. Usted sigue siendo responsable de todos los costos adicionales en los que incurra
          en el arbitraje, incluidos, entre otros, los honorarios denabogados, costo e acceso al servicio proporcionado
          por Kleros o informes de testigos expertos. </li>
        <li><span class="fw-bold">Autoridad del árbitro Kleros: </span>El arbitraje proporcionado por Kleros tendrá
          autoridad exclusiva para (a) determinar el alcance y la exigibilidad de este Acuerdo de arbitraje y (b)
          resolver cualquier disputa relacionada con la interpretación, aplicabilidad, exigibilidad o formación de este
          Acuerdo de arbitraje, incluido, entre otros, cualquier reclamo que todo o parte de este Acuerdo de Arbitraje
          es nulo o anulable. El arbitraje decidirá los derechos y responsabilidades, si los hubiere, suyos y de
          CryptoCampo. El procedimiento de arbitraje no se consolidará con ningún otro asunto ni se unirá a ningún otro
          caso o parte. El árbitro tendrá la autoridad para otorgar mociones dispositivas de todo o parte de cualquier
          reclamo. El árbitro tendrá la autoridad para otorgar daños monetarios y otorgar cualquier remedio o reparación
          no monetaria disponible para un individuo bajo la ley aplicable, las reglas del foro arbitral, y estos
          Términos. El árbitro tiene la misma autoridad para otorgar reparación de forma individual que tendría un juez
          en un tribunal de justicia. El laudo del árbitro es definitivo y vinculante para usted y para nosotros.</li>
        <li><span class="fw-bold">Renuncia al juicio por jurado: </span>STED Y CRYPTOCAMPO RENUNCIAN A CUALQUIER DERECHO
          CONSTITUCIONAL Y ESTATUTARIO DE DEMANDAR EN LOS TRIBUNALES Y TENER UN JUICIO ANTE UN JUEZ O JURADO. En cambio,
          usted y CryptoCampo eligen que todas las reclamaciones y disputas se resuelvan mediante arbitraje en virtud de
          este Acuerdo de arbitraje, utilizando el sistema de resolución de conflictos proporcionado por Kleros.</li>
        <li><span class="fw-bold">Renuncia a demandas colectivas y arbitrajes colectivos: </span>TODOS LOS RECLAMOS Y
          DISPUTAS DENTRO DEL ALCANCE DE ESTE ACUERDO DE ARBITRAJE DEBEN SER ARBITRADOS DE FORMA INDIVIDUAL Y NO DE
          FORMA REPRESENTANTE (INCLUYENDO, SIN LIMITACIÓN, PAGA) O DE CLASE COLECTIVA. SÓLO ESTÁ DISPONIBLE LA
          COMPENSACIÓN INDIVIDUAL Y LOS RECLAMOS DE MÁS DE UN USUARIO, PERSONA O ENTIDAD NO PUEDEN SER ARBITRADAS NI
          CONSOLIDADAS CON LAS DE CUALQUIER OTRO USUARIO, PERSONA O ENTIDAD. En consecuencia, según los procedimientos
          de arbitraje descritos en esta sección, un árbitro no combinará ni consolidará los reclamos de más de una
          parte sin el consentimiento por escrito de todas las partes afectadas en un procedimiento de arbitraje. Sin
          limitar la generalidad de lo anterior, usted y CryptoCampo acuerdan que ninguna disputa procederá mediante
          arbitraje colectivo sin el consentimiento por escrito de todas las partes afectadas. Si se emite una decisión
          que establece que la ley aplicable impide la aplicación de cualquier parte de las limitaciones de esta
          subsección en cuanto a un reclamo de reparación dado, entonces ese reclamo debe separarse del arbitraje y
          presentarse en los tribunales estatales o federales ubicados en el condado de Nueva York en el Estado de Nueva
          York. Todos los demás reclamos serán arbitrados.</li>
        <li><span class="fw-bold">Divisibilidad: </span>Salvo lo dispuesto en esta Sección, si se determina que alguna
          parte o partes de este Acuerdo de arbitraje son inválidas o inaplicables conforme a la ley, entonces dicha
          parte o partes específicas no tendrán fuerza ni efecto y se separarán y el resto del Arbitraje Acuerdo
          continuará en pleno vigor y efecto.</li>
        <li><span class="fw-bold">Supervivencia del Acuerdo: </span>Este Acuerdo de arbitraje sobrevivirá a la
          terminación de su relación con CryptoCampo.</li>
        <li><span class="fw-bold">Modificación: </span>Sin perjuicio de cualquier disposición en contrario en estos
          Términos, aceptamos que si CryptoCampo realiza algún cambio sustancial en el futuro a este Acuerdo de
          arbitraje, puede rechazar ese cambio dentro de los treinta (30) días posteriores a la entrada en vigencia de
          dicho cambio escribiendo a CryptoCampo a la siguiente dirección: soporte@cryptocampo.net, con copia a
          info@cryptocampo.net y también a desarrollo@cryptocampo.net</li>
      </ul>
      <li class="fw-bold">Ley aplicable y jurisdicción</li>
      <div class="textos mb-3">
        Estos Términos y su acceso y uso del Servicio se regirán, interpretarán y aplicarán de acuerdo con las leyes del
        Estado de Nueva York. Cualquier disputa entre las partes que no esté sujeta a arbitraje según lo establecido en
        la Sección 16 o que no pueda ser escuchada en un tribunal de reclamos menores, se resolverá en los tribunales
        estatales o federales del condado de Nueva York en el estado de Nueva York y los Estados Unidos. ,
        respectivamente, con sede en el Estado de Nueva York.</div>
      <li class="fw-bold">Terminación</li>
      <div class="textos mb-3">
        Si incumple cualquiera de las disposiciones de estos Términos, todas las licencias otorgadas por CryptoCampo
        terminarán automáticamente. Además, sin perjuicio de lo contenido en estos Términos, nos reservamos el derecho,
        con o sin previo aviso y a nuestro exclusivo criterio, de suspender, deshabilitar, cancelar o eliminar su Cuenta
        y/o su capacidad para acceder o utilizar el Servicio (o cualquier parte de lo anterior) en cualquier momento y
        por cualquier motivo o sin él, y usted reconoce y acepta que no tendremos ninguna responsabilidad u obligación
        con usted en tal caso y que no tendrá derecho a un reembolso de los montos que ya ha pagado para nosotros.</div>
      <li class="fw-bold">Divisibilidad</li>
      <div class="textos mb-3">
        Si algún término, cláusula o disposición de estos Términos se considera inválido o inaplicable, entonces ese
        término, cláusula o disposición se podrá separar de estos Términos y no afectará la validez o aplicabilidad de
        cualquier parte restante de ese término, cláusula o disposición. disposición, o cualquier otro término, cláusula
        o disposición de estos Términos.</div>
      <li class="fw-bold">Medidas cautelares</li>
      <div class="textos mb-3">
        Usted acepta que el incumplimiento de estos Términos causará daños irreparables a CryptoCampo por los cuales los
        daños monetarios no serían un remedio adecuado y CryptoCampo tendrá derecho a una compensación equitativa además
        de cualquier remedio que pueda tener en virtud del presente o por ley sin una fianza, otra garantía , o prueba
        de daños.</div>
      <li class="fw-bold">Leyes de exportación</li>
      <div class="textos mb-3">
        Usted acepta que no exportará ni reexportará, directa o indirectamente, el Servicio y/u otra información o
        materiales proporcionados por CryptoCampo en virtud del presente, a ningún país para el cual los Estados Unidos
        o cualquier otra jurisdicción relevante requiera una licencia de exportación u otra aprobación gubernamental en
        el momento de la exportación sin obtener primero dicha licencia o aprobación. En particular, pero sin limitarse
        a ello, el Servicio no se puede exportar ni reexportar (a) a ningún país bajo embargo de los EE. UU. ni a ningún
        país que haya sido designado por el gobierno de los EE.UU incluido en cualquier lista del gobierno de EE. UU. de
        partes prohibidas o restringidas, incluida la lista de ciudadanos especialmente designados del Departamento del
        Tesoro de EE. UU. o la lista de personas o entidades denegadas del Departamento de Comercio de EE. UU. Al
        utilizar el Servicio, usted declara y garantiza que no se encuentra en ninguno de esos países ni en ninguna de
        esas listas. Usted es responsable y por la presente acepta cumplir, a su exclusivo cargo, con todas las leyes y
        reglamentaciones de exportación aplicables de los Estados Unidos.
      </div>
      <li class="fw-bold">Supervivencia</li>
      <div class="textos mb-3">
        Todas las secciones que, por su naturaleza, deban sobrevivir a la rescisión de estos Términos continuarán en
        pleno vigor y efecto después y sin perjuicio de la rescisión de estos Términos por parte de CryptoCampo o usted.
        La rescisión no limitará ninguno de los otros derechos o recursos de CryptoCampo por ley o equidad.</div>
      <li class="fw-bold">Misceláneas</li>
      <div class="textos mb-3">
        Estos Términos constituyen el acuerdo completo entre usted y CryptoCampo en relación con su acceso y uso del
        Servicio. Estos Términos, y cualquier derecho y licencia otorgados en virtud del presente, no pueden ser
        transferidos o asignados por usted sin el consentimiento previo por escrito de CryptoCampo, y el hecho de que
        CryptoCampo no haga valer ningún derecho o disposición en virtud de estos Términos no constituirá una renuncia a
        tal derecho o disposición. Ninguna renuncia de ninguna de las partes a cualquier incumplimiento o incumplimiento
        en virtud del presente se considerará una renuncia a cualquier incumplimiento o incumplimiento anterior o
        posterior. Los encabezados de las secciones utilizados en este documento son solo para referencia y no se
        interpretarán como si tuvieran ningún efecto legal.</div>
      <div class="textos mb-3">
        Usted y CryptoCampo acuerdan que la Convención de las Naciones Unidas sobre Contratos para la Venta
        Internacional de Mercaderías no se aplicará a la interpretación o construcción de estos Términos.</div>
      <div class="textos mb-3">
        Salvo que se disponga lo contrario en el presente, estos Términos están destinados únicamente al beneficio de
        las partes y no pretenden conferir derechos de terceros beneficiarios a ninguna otra persona o entidad.</div>
    </ol>
  </div>
</template>

<script>
export default {
  components: {
  },
}
</script>

<style lang="css" scoped>
.main-content {
  min-height: 50vh;
  background-color: white;
}
</style>